import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { findAllEmployeeAction } from "../../../actions/employeeManagmentAction";
import { Skeleton, Typography } from "@mui/material";
import ColumnVisible from "../../extraComponents/ColumnVisible";
import { TableData } from "../../Table/Table";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import LeadsFilterSearch from "../../Leads/LeadsFilterSearch";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";

function EmployeeMain() {
  const dispatch = useDispatch();

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [data, setData] = useState([]);
  const [reset, setReset] = useState(false);
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(25);

  let { deleteEmployeeSuccess } = useSelector((state) => {
    return state.deleteEmployee;
  });

  let { employeeStatusUpdateSuccess } = useSelector((state) => {
    return state.updateEmployeeStatus;
  });

  // selector for Employee bulkUpload

  let {
    employeeBulkUploadLoading,
    employeeBulkUploadSuccess,
    employeeBulkUploadError
  } = useSelector((state) => {
    return state.employeeBulkUpload;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    dispatch(findAllEmployeeAction());
  }, [
    dispatch,
    deleteEmployeeSuccess,
    employeeStatusUpdateSuccess,
    employeeBulkUploadSuccess
  ]);

  // selectors

  let { findEmployeeLoading, findEmployeeSuccess, findEmployeeErr } =
    useSelector((state) => {
      return state.findAllEmployee;
    });

  // Success popUp for Employee bulkUpload

  useEffect(() => {
    if (employeeBulkUploadSuccess && successAlertMsgSuccess) {
      Swal.fire("Uploaded", `Employeee created successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
    if (employeeBulkUploadError && successAlertMsgErr) {
      Swal.fire("Failed!", "Employee created Failed", "error");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    employeeBulkUploadSuccess,
    employeeBulkUploadError,
    successAlertMsgSuccess,
    successAlertMsgErr
  ]);

  useEffect(() => {
    if (findEmployeeSuccess) {
      setAllEmployeeData(
        findEmployeeSuccess?.filter((value) => value?.firstName !== "admin")
      );
    }
  }, [findEmployeeSuccess, deleteEmployeeSuccess, reset]);

  // useEffect(() => {
  //   if (findEmployeeErr) {
  //     Swal.fire("Something Went Wrong!", `${findEmployeeErr}`, "error");
  //   }
  // }, [findEmployeeErr]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Employee column Visibility
  const [showEmployeeNameColumn, setShowEmployeeNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showMobileColumn, setShowMobileColumn] = useState(false);
  const [showRollColumn, setShowRollColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);

  const [columnName, setColumnName] = useState("");

  const toggleNameColumn = (status, column) => {
    if (column === "employeeName") {
      setColumnName(column);
      setShowEmployeeNameColumn(status);
    }
    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }
    if (column === "mobile") {
      setColumnName(column);
      setShowMobileColumn(status);
    }
    if (column === "role") {
      setColumnName(column);
      setShowRollColumn(status);
    }
    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("Name");
    const storedDescriptionNameColumn = localStorage.getItem("email");
    const storedUpdatedbyColumn = localStorage.getItem("mobile");
    const storedLastUpdatebyColumn = localStorage.getItem("role");

    if (storedRollNameColumn !== null) {
      setColumnName("Name");
      setShowEmployeeNameColumn(JSON.parse(storedRollNameColumn));
    }
    if (storedDescriptionNameColumn !== null) {
      setColumnName("email");
      setShowMobileColumn(JSON.parse(storedDescriptionNameColumn));
    }
    if (storedUpdatedbyColumn !== null) {
      setColumnName("mobile");
      setShowEmailColumn(JSON.parse(storedUpdatedbyColumn));
    }
    if (storedLastUpdatebyColumn !== null) {
      setColumnName("role");
      setShowRollColumn(JSON.parse(storedLastUpdatebyColumn));
    }
    if (storedLastUpdatebyColumn !== null) {
      setColumnName("role");
      setShowRollColumn(JSON.parse(storedLastUpdatebyColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("Name", JSON.stringify(showEmployeeNameColumn));
    localStorage.setItem("email", JSON.stringify(showMobileColumn));
    localStorage.setItem("mobile", JSON.stringify(showEmailColumn));
    localStorage.setItem("role", JSON.stringify(showRollColumn));
  }, [
    showEmployeeNameColumn,
    showMobileColumn,
    showEmailColumn,
    showRollColumn,
    columnName
  ]);

  useEffect(() => {
    let array = allEmployeeData;
    // ?.reverse();
    setData(
      array?.map((employee, index) => ({
        sl: index + 1,
        employeeId:employee.employeeId,
        id: employee.id,
        name: employee.firstName,
        description: employee.email,
        updatedby: employee?.mobile_countryCode
          ? employee?.mobile_countryCode + " " + employee.mobile
          : employee.mobile,
        lastupdateby: employee?.role?.roleName,
        branch: employee?.user_Branches
          ? employee?.user_Branches.map(
              (value) => value?.branch_master?.branchName
            )
          : null,
        status: employee.status
      }))
    );
  }, [allEmployeeData]);

  console.log(findEmployeeSuccess, "findEmployeeSuccessfindEmployeeSuccess");

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Employee ID",
      field: "employeeId",
      hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Employee Name",
      field: "name",
      hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Mail ID",
      field: "email",
      hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Mobile",
      field: "mobile",
      hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Role",
      field: "role",
      hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },

    {
      title: "Branch",
      field: "Branch",
      hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "Status",
      field: "status",
      hidden: showStatusColumn,
      headerStyle: { backgroundColor: "#F9F9FB" }
    },
    {
      title: "",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" }
    }
  ];

  // pagination
  const handlePaginationEntry = (e) => {
    let value = e.target.value;
    if (value <= 100) {
      setPagiantionEntry(value);
    }
  };

  // filter
  const LeadFilterFunction = (data, filterOption) => {
    if (filterOption !== "Status") {
      setAllEmployeeData(
        allEmployeeData?.filter((item) => {
          return item.user_Branches.toLowerCase().includes(data.toLowerCase());
        })
      );
    }

    if (filterOption == "Status") {
      console.log(data,"dataaaaaaaaaaaaaaaaa")
      const filterItem = findEmployeeSuccess?.filter((value) => value?.firstName !== "admin")
      setAllEmployeeData(
        filterItem?.filter((item) => {
          return item.status.toLowerCase() == data.toLowerCase();
        })
      );
    }

    setwhichFilter(filterOption);
    setFilterPassData(data);
  };

  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
  };

  const searchHandleChange = (e) => {
    console.log(e.target.value, "ccdcsdcdscsdcscscscscscscsdcdcsdsd");
    console.log(findEmployeeSuccess, "cdcdscsdcdscincosindicnsodncosdncodsn");

    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          return (
            item.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
            // item?.description
            //   ?.toLowerCase()
            //   ?.includes(e.target.value.toLowerCase()) ||
            // item.updatedby
            //   .toLowerCase()
            //   .includes(e.target.value.toLowerCase()) ||
            // item.lastupdateby
            //   .toLowerCase()
            //   .includes(e.target.value.toLowerCase()) ||
            item.lastupdateby
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        allEmployeeData?.map((employee, index) => ({
          sl: index + 1,
          employeeId:employee.employeeId,
          id: employee.id,
          name: employee.firstName,
          description: employee.email,
          updatedby: employee.mobile,
          lastupdateby: employee?.role?.roleName,
          branch: employee?.user_Branches
            ? employee?.user_Branches.map(
                (value) => value?.branch_master?.branchName
              )
            : null,
          status: employee.status
        }))
      );
    }
  };
  // material Table export

  // const handleExportPDF = (tableData) => {
  //   exportToPDF(tableData);
  // };

  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Employee_Managment");
  };

  const pdfDownloadClick = () => {
    DownloadPdf(columns, data, "Employee_Managment", "Employee Managment");
  };

  if (!hasPrivilege("employee managment") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("payslip"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <>
      <ColumnVisible
        columnVisibleHandler={toggleNameColumn}
        pdfDownloadClick={pdfDownloadClick}
        component="employee"
        employeeRow1={showEmployeeNameColumn}
        employeeRow2={showEmailColumn}
        employeeRow3={showMobileColumn}
        employeeRow4={showRollColumn}
        columnName={columnName}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        excelDownloadClick={excelDownloadClick}
        subMenu="employee managment"
      />

      {findEmployeeLoading || employeeBulkUploadLoading ? (
        //  <Skeleton variant="rect" width="100%" height={500} />

        <>
          {/* <Skeleton /> */}
          <Typography variant="h1">
            <Skeleton animation={false} />
          </Typography>
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>
          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>
          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>
          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>
          <Skeleton animation={false} />
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={2.5} sx={{ mt: "-20px" }}>
              <LeadsFilterSearch
                statusComponent="employee"
                LeadFilterFunction={LeadFilterFunction}
                handleResetData={handleResetData}
                pagiantionEntry={pagiantionEntry}
                handlePaginationEntry={handlePaginationEntry}
              />
            </Grid>

            <Grid item xs={9.5}>
              <TableData
                data={data}
                columns={columns}
                allRoleFindSuccess={findEmployeeSuccess}
                showRollNameColumn={showEmployeeNameColumn}
                showDescriptionNameColumn={showEmailColumn}
                showUpdatebyNameColumn={showMobileColumn}
                showLastUpdatebyNameColumn={showRollColumn}
                showStatusColumn={showStatusColumn}
                component="employee"
                showUrl="/Administration/employee/details"
                pagiantionEntry={pagiantionEntry}
                paginationPageNumber={paginationPageNumber}
                subMenu="employee managment"
                // requestSort={requestSort}
                // sortOrder={sortOrder}
                // sortBy={sortBy}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default EmployeeMain;
