import React, { useEffect, useState } from "react";

import { FcRemoveImage } from "react-icons/fc";
import { RxCross1 } from "react-icons/rx";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField
} from "@mui/material";

import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useParams } from "react-router-dom";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";

import {
  branchManagerFetchAction,
  findAllEmployeeAction,
  leaveApplyAction
} from "../../actions/employeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import {
  leadChangeStatusAction,
  leadCounsilorNoteCreateAction,
  leadDuplicateRejectAction,
  leadOwnerChangeAction,
  leadOwnerChangeApprovalRequestRejectAction,
  leadRefundCreateAction,
  leadRefundUpdateAction,
  leadStatusApprovingChangeAction,
  leadTelecallerNoteCreateAction,
  pendingLeadStatusDocsAction
} from "../../actions/leadActions/leadActions";
import { SubmitButton, UploadButton } from "./Buttons.js";
import InputField, {
  SelectInputField,
  TopInputFieldBorderLabel
} from "./InputField";
import { Box } from "@mui/system";
import { Button, Checkbox, Grid, Typography } from "@mui/material";
import {
  followUpActivitiesAddActionAction,
  followUpActivitiesAddAction,
  followUpComposeMailAction
} from "../../actions/leadActions/leadFollowUpActions";
import Swal from "sweetalert2";
import "./LeadTable.css";
import {
  hrEmployeeHolidayCreateAction,
  hrEmployeeHolidayCreateEditAction,
  hrEmployeeHolidayEditAction
} from "../../actions/HrActions/hrEmployeeManagmentAction";
import { successTrueMsgAction } from "../../actions/globalAction.js";
import { pink } from "@mui/material/colors";
import currencyToSymbolMap from "currency-symbol-map/map";
import {
  applicationDepositeCreateAction,
  findByIdLeadApplicationAction
} from "../../actions/aplicationActions/applicationsAction.js";
import { EMPLOYEE_LEAVE_APPLY_SUCCESS } from "../../constants/employeeManagmentConstant.js";
import { GetCountries } from "react-country-state-city";

export default function Dialogue({
  openTrue,
  handleClose,
  headerName,
  Dailogcomponent,
  appliedApplicationId,
  width,
  height,
  margintop,
  modalData,
  applicationCountry,
  handleFunction,
  menu,
  handler,
  leadID
}) {
  console.log(appliedApplicationId, "appliedApplicationIdappliedApplicationId");
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Note",
      field: "Note"
    },
    {
      title: "Date",
      field: "Date"
    },
    {
      title: "CreatedBy",
      field: "CreatedBy"
    }
  ];

  const dispatch = useDispatch();
  const userId = useParams().id;
  const symbolsArray = Object.values(currencyToSymbolMap);

  const [date, setNewDate] = useState(new Date());
  const [open, setOpen] = useState(openTrue);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedStyle, setSelectedStyle] = useState("NORMAL");
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);
  const [bgColor, setBgColor] = useState("transparent");
  const [textColor, setTextColor] = useState("black");

  const [universityHandler, setUniversityHandler] = useState("");
  const [universityHandlerData, setUniversityHandlerData] = useState([]);

  const [universityId, setUniversityId] = useState("");
  const [reason, setReason] = useState("");

  let currentDate = dayjs();
  const [subject, setSubject] = useState("");
  const [dueData, setDueData] = useState(currentDate);
  const [priority, setPriority] = useState(
    headerName == "Create Meeting" ? dayjs() : ""
  );
  const [owner, setOwner] = useState("");
  const [status, setStatus] = useState("");
  const [reminder, setReminder] = useState();
  const [repeat, setRepeat] = useState("");
  const [meetLink, setMeetLink] = useState("");
  const [description, setDescription] = useState("");

  const [emailTo, setEmailTo] = useState("");
  const [emailCc, setEmailCc] = useState("");
  const [bcc, setBcc] = useState("");

  const [leadAgreement, setLeadAgreement] = useState(null);
  const [visaSummary, setVisaSummary] = useState(null);
  const [offerLetter, setOfferLetter] = useState(null);
  const [leadStatus, setLeadStatus] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [paymentDate, setPaymentDate] = useState(date);
  const [offerRecieveDetails, setOfferRecieveDetails] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({});
  const [receptNo, setReceptNo] = useState("");
  const [cardDetail, setCardDetail] = useState("");
  const [attachment, setAttachment] = useState("");

  // Leave Application modal

  const type = ["vacation", "Sick", "Personal"];

  const [employeeId, setEmployeeId] = useState("");
  const [mailId, setMailId] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [isFullDay, setIsFullDay] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [toDateError, setToDateError] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [allEmployees, setAllEmployeeData] = useState([]);
  const [id, setId] = useState("");
  const [error, setError] = useState("");

  // declaration
  const [declarationFile, setDeclarationFile] = useState();
  const [declarationFileAsBase64, setDeclarationFileAsBase64] = useState("");

  const [submitteddeclarationFile, setsubmittedDeclarationFile] = useState("");
  const [
    submitteddeclarationFileAsBase64,
    setsubmittedDeclarationFileAsBase64
  ] = useState("");
  const [offerLetterError, setOfferLetterError] = useState("");

  // tution fee

  const [discount, setDiscount] = useState(false);
  const [scholarShip, setScholarShip] = useState(false);
  const [symbolArray, setSymbolArray] = useState([]);

  // Counsilor and teleCaller Note

  const [note, setNote] = useState("");
  const [balaceAmountAdd, setBalanceAmount] = useState("");

  const [formValue, setFormValue] = useState({
    currency: "",
    totalTutionFee: 0,
    scholarShipName: "",
    scholarShipAmount: 0,
    discountAmount: 0,
    totalFeeNeedtoPaid: "",
    paidAmount: 0,
    paymentDate: "",
    paymentTime: "",
    receipt: "",
    balanceAmount: 0,
    remarks: ""
  });

  // Refund state

  const [refundCurrency, setRefundCurrency] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundError, setrefundError] = useState({});

  // UseEffect to add data to the refundState for refund edit

  useEffect(() => {
    if (modalData) {
      setRefundCurrency(modalData?.currency);
      setRefundAmount(modalData?.amount);
    }
  }, [modalData]);

  const [paymentFeeFormError, setPaymentFeeFormError] = useState({});

  const handleDeclarationFile = (e) => {
    setDeclarationFile(e.target.files[0]);
    const file = e.target.files[0];
    // Create a Promise to read the file and convert it to base64
    const filePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    filePromise
      .then((base64Array) => {
        console.log(base64Array);
        setDeclarationFileAsBase64(base64Array);
      })
      .catch((error) => {
        console.log("Error converting files to base64 : ", error);
      });
  };

  const handleSubmittedDeclarationFile = (e) => {
    setsubmittedDeclarationFile(e.target.files[0]);
    const file = e.target.files[0];
    // Create a Promise to read the file and convert it to base64
    const filePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    filePromise
      .then((base64Array) => {
        console.log(base64Array);
        setsubmittedDeclarationFileAsBase64(base64Array);
      })
      .catch((error) => {
        console.log("Error converting files to base64 : ", error);
      });
  };

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  // Desposite create reducer

  const { applicationDepositeCreateSuccess, applicationDepositeCreateError } =
    useSelector((state) => {
      return state.applicationDepositeCreate;
    });

  const { hrEmployeeLeaveApplicationApplySuccess } = useSelector((state) => {
    return state.hrEmployeeLeaveApplicationApply;
  });

  let { applicationFindByIdSuccess } = useSelector((state) => {
    return state.findByIdLeadApplication;
  });

  let { findEmployeeLoading, findEmployeeSuccess, findEmployeeErr } =
    useSelector((state) => {
      return state.findAllEmployee;
    });
  // console.log(employeeId, "employeeIdemployeeId");
  useEffect(() => {
    if (findEmployeeSuccess) {
      let employeeList = findEmployeeSuccess?.map((item) => ({
        id: item.id,
        employeeId: item.employeeId,
        firstName: item.firstName
      }));
      console.log(employeeList, "employeeList");
      // setAllEmployeeData(findEmployeeSuccess);
      setAllEmployeeData(employeeList);
    }
  }, [findEmployeeSuccess]);

  useEffect(() => {
    setUniversityHandler(handler?.replace(/"/g, ""));
  }, [handler]);

  useEffect(() => {
    if (applicationFindByIdSuccess) {
      setFormValue({});
    }
  }, [applicationFindByIdSuccess]);

  console.log(
    applicationFindByIdSuccess,
    "applicationFindByIdSuccessapplicationFindByIdSuccess"
  );

  useEffect(() => {
    dispatch(findAllEmployeeAction());
    GetCountries().then((result) => {
      setSymbolArray(result);
    });
  }, [dispatch]);

  useEffect(() => {
    if (successAlertMsgSuccess && hrEmployeeLeaveApplicationApplySuccess) {
      Swal.fire("Good job!", `leave applied successfully`, "success").then(
        (value) => {
          dispatch({ type: EMPLOYEE_LEAVE_APPLY_SUCCESS, payload: false });
        }
      );
      dispatch(successTrueMsgAction(false));
      handleClose();
    }
  }, [
    successAlertMsgSuccess,
    hrEmployeeLeaveApplicationApplySuccess,
    dispatch
  ]);

  const handleEmployeeIdField = (e) => {
    console.log(e.target.value);
    setEmployeeId(
      e.target.value
      // {
      //   id: e.target.value.id,
      //   employeeId: e.target.value.employeeId,
      // }
    );
    setId(e.target.value.id);
    setError((prev) => ({
      ...prev,
      ["employeeId"]: ""
    }));
  };

  const validateLeaveApplication = () => {
    let errorList = {};

    if (Object.keys(employeeId).length === 0) {
      errorList.employeeId = "please choose a employee";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!mailId) {
      errorList.mailId = "please enter a mail id";
    } else if (!emailRegex.test(mailId)) {
      errorList.mailId = "please enter a valid mail id";
    }

    setError(errorList);
    console.log(errorList, "errorList");
    return Object.keys(errorList).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    // if (employeeId && mailId) {
    //   console.log(
    //     id,
    //     employeeId,
    //     mailId,
    //     leaveType,
    //     isFullDay,
    //     dateFrom,
    //     dateTo,
    //     leaveReason,
    //     "dasdasdasdasdasdasd"
    //   );

    //   dispatch(
    //     leaveApplyAction({
    //       id,
    //       employeeId,
    //       mailId,
    //       leaveType,
    //       isFullDay: isFullDay,
    //       dateFrom,
    //       dateTo,
    //       leaveReason,
    //     })
    //   );
    //   handleClose();
    //   setEmployeeId("");
    //   setMailId("");
    //   setLeaveType("");
    //   setIsFullDay(false);
    //   setDateFrom("");
    //   setDateTo("");
    //   setLeaveReason("");
    // } else {
    //   alert("Please add employee Id and email");
    // }
    if (validateLeaveApplication()) {
      dispatch(
        leaveApplyAction({
          id,
          employeeId,
          mailId,
          leaveType,
          isFullDay: isFullDay,
          dateFrom,
          dateTo,
          leaveReason
        })
      );
      dispatch(successTrueMsgAction(true));
    }
  };

  // HR_CREATE_HOLIDAY_HANDLING_STATES

  const data = [
    "Januvary",
    "Februvary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  // HR_CREATE_HOLIDAY_EEDIT_HANDLING_SELECTOR

  let { hrEmployeeEditSuccess } = useSelector((state) => {
    return state.hrEmployeeHolidayEdit;
  });

  useEffect(() => {
    if (Dailogcomponent === "holidayEdit") {
      dispatch(hrEmployeeHolidayEditAction(appliedApplicationId));
    }
  }, [dispatch, appliedApplicationId, Dailogcomponent]);

  // Selector_Get_Data_From_Creation

  const [holidaymonth, SetHolidayMonth] = useState("");
  const [LeaveCount, setLeaveCount] = useState("");
  const [Holidayname, setHolidayname] = useState("");
  const [HolidayDate, setHolidayDate] = useState(date);
  const [Editholidaymonth, SetEditHolidayMonth] = useState("");
  const [EditLeaveCount, setEditLeaveCount] = useState("");
  const [EditHolidayname, setEditHolidayname] = useState("");
  const [EditHolidayDate, setEditHolidayDate] = useState("");
  const [offerLetterType, setOfferLettertype] = useState("");
  const [errors, setErrors] = useState();
  const [intakeMonth, setIntakeMonth] = useState(new Date());
  const [intakeYear, setIntakeYear] = useState(new Date());

  // handling errors
  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    if (hrEmployeeEditSuccess) {
      SetEditHolidayMonth(
        hrEmployeeEditSuccess ? hrEmployeeEditSuccess.Holiday_Month : ""
      );
      setEditLeaveCount(
        hrEmployeeEditSuccess ? hrEmployeeEditSuccess.Leave_count : ""
      );
      setEditHolidayname(
        hrEmployeeEditSuccess ? hrEmployeeEditSuccess.Holiday_Name : ""
      );
      setEditHolidayDate(
        hrEmployeeEditSuccess ? hrEmployeeEditSuccess.Holiday_Date : ""
      );
    }
  }, [hrEmployeeEditSuccess]);

  let { branchManagerLoading, branchManagerSuccess, branchManagerError } =
    useSelector((state) => {
      return state.branchManagerFetch;
    });

  useEffect(() => {
    dispatch(
      branchManagerFetchAction(
        Dailogcomponent === "application handler" ? userId : id,
        Dailogcomponent === "Interviewer"
          ? "Interviewer"
          : Dailogcomponent === "visa officer"
          ? "visa officer"
          : "university handler"
      )
    );
  }, [dispatch, id, userId]);

  useEffect(() => {
    if (branchManagerSuccess) {
      setUniversityHandlerData(branchManagerSuccess);
    }
  }, [branchManagerSuccess]);

  // function for checking email Pattern
  const isValidEmail = (email) => {
    // Regular expression pattern to match email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  // for offer latter
  const handleofferFileChange = (event) => {
    setLeadAgreement(event?.target.value);
    const file = event?.target?.files[0];
    // Create a Promise to read the file and convert it to base64
    const filePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    filePromise
      .then((base64Array) => {
        console.log(base64Array);
        setOfferLetter(base64Array);
      })
      .catch((error) => {
        console.log("Error converting files to base64 : ", error);
      });
  };

  // for visa
  const handleFileChange = (event) => {
    setLeadAgreement(event?.target.value);
    const file = event?.target?.files[0];
    // Create a Promise to read the file and convert it to base64
    const filePromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    filePromise
      .then((base64Array) => {
        console.log(base64Array);
        setVisaSummary(base64Array);
      })
      .catch((error) => {
        console.log("Error converting files to base64 : ", error);
      });
  };

  const validate = () => {
    const newErrors = {};
    if (holidaymonth === "") {
      newErrors.holidaymonth = "Month is required";
    }

    if (LeaveCount === "") {
      newErrors.LeaveCount = "Days is required";
    }

    if (Holidayname === "") {
      newErrors.Holidayname = "Holiday name is required";
    }

    if (HolidayDate === date) {
      newErrors.HolidayDate = "Holiday Date is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleMethodApplyClick = () => {
    if (Dailogcomponent === "university handler") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          universityHandler,
          "university handler",
          appliedApplicationId
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "Reason") {
      dispatch(
        leadChangeStatusAction(modalData, appliedApplicationId, reason, menu)
      );
      handleClose();
    }
    if (Dailogcomponent === "deferalReason") {
      const validate = () => {
        const newErrors = {};
        if (intakeMonth === "") {
          newErrors.intakeMonth = "Month is required";
        }

        if (intakeYear === "") {
          newErrors.intakeYear = "Year is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };

      if (validate()) {
        dispatch(
          leadChangeStatusAction(
            modalData,
            appliedApplicationId,
            reason,
            menu,
            intakeMonth,
            intakeYear
          )
        );
        handleClose();
      }
    }
    if (Dailogcomponent === "RejectReason") {
      dispatch(
        pendingLeadStatusDocsAction(appliedApplicationId, modalData, reason)
      );
      handleClose();
      Swal.fire("success!", "Lead status changed.", "success");
    }

    if (Dailogcomponent === "leadOwnerChangeApproval") {
      dispatch(
        leadOwnerChangeApprovalRequestRejectAction(
          appliedApplicationId,
          modalData,
          reason
        )
      );
      handleClose();
      Swal.fire("success!", "Lead ownerChange rejected.", "success");
    }

    if (Dailogcomponent === "statusApprovalReject") {
      dispatch(
        leadStatusApprovingChangeAction({
          status: "rejected",
          leadId: appliedApplicationId,
          statusId: modalData,
          requestStatus: reason
        })
      );
      handleClose();
      Swal.fire("Rejected!", "request has been rejected.", "success");
    }

    if (Dailogcomponent === "application handler") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          universityHandler,
          "application handler",
          appliedApplicationId
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "Interviewer") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          universityHandler,
          "Interviewer",
          appliedApplicationId
        )
      );
      handleClose();
    }

    if (Dailogcomponent == "visa officer") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          universityHandler,
          "visa officer",
          appliedApplicationId
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "university ID") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          universityHandler,
          "university ID",
          appliedApplicationId,
          universityId,
          visaSummary
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "Offer Letter Received") {
      if (offerLetter !== null) {
        setOfferRecieveDetails({
          ...offerRecieveDetails,
          paymentDate: paymentDate
        });
        dispatch(
          leadOwnerChangeAction(
            "",
            userId,
            offerRecieveDetails,
            universityHandler,
            Dailogcomponent,
            appliedApplicationId,
            universityId,
            offerLetter,
            offerLetterType
          )
        );
        handleClose();
      } else {
        setOfferLetterError("Offer letter mandatory");
      }
    }

    if (Dailogcomponent === "visaDetails") {
      let visaDetails = {
        leadStatus: leadStatus,
        appliedDate: appliedDate,
        paymentDate: paymentDate,
        receptNo: receptNo,
        cardDetail: cardDetail
      };
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          universityHandler,
          "visaDetails",
          appliedApplicationId,
          universityId,
          visaSummary,
          visaDetails
        )
      );
      handleClose();
    }

    if (Dailogcomponent == "Declaraton") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          "",
          "Submitted declaration",
          appliedApplicationId,
          "",
          "",
          "",
          declarationFileAsBase64
        )
      );
      handleClose();
    }

    if (Dailogcomponent == "declarationCompleted") {
      dispatch(
        leadOwnerChangeAction(
          "",
          userId,
          "",
          "",
          "Declaration Completed",
          appliedApplicationId,
          "",
          "",
          "",
          submitteddeclarationFileAsBase64
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "taskCreate") {
      const validateTaskCreate = () => {
        const newErrors = {};
        if (subject.trim() === "") {
          newErrors.subject = "Subject is required";
        }
        if (description.trim() === "") {
          newErrors.description = "Description is required";
        }
        if (
          typeof dueData !== "string" &&
          dueData?.hour() === currentDate?.hour() &&
          dueData?.minute() === currentDate?.minute()
        ) {
          newErrors.dueData = "Due Date cannot be current time";
        }

        setErrorData(newErrors);
        return Object.keys(newErrors).length === 0;
      };

      if (validateTaskCreate()) {
        dispatch(
          followUpActivitiesAddActionAction(
            subject,
            dueData,
            priority,
            owner,
            status,
            reminder,
            repeat,
            "",
            description,
            userId,
            Dailogcomponent
          )
        );
        handleClose();
      }
    }

    if (Dailogcomponent === "meetCreate") {
      const validateMeetCreate = () => {
        const newErrors = {};
        if (subject.trim() === "") {
          newErrors.subject = "Subject is required";
        }

        if (
          typeof dueData !== "string" &&
          dueData.hour() === currentDate.hour() &&
          dueData.minute() === currentDate.minute()
        ) {
          newErrors.dueData = "Current time cannot be selected";
        }

        if (meetLink.trim() === "") {
          newErrors.meetLink = "Meet link is required";
        }

        if (description.trim() === "") {
          newErrors.description = "Description is required";
        }

        setErrorData(newErrors);
        return Object.keys(newErrors).length === 0;
      };

      const isoFormat = dueData.toISOString();
      const isoFormatPriority = priority.toISOString();

      if (validateMeetCreate()) {
        dispatch(
          followUpActivitiesAddActionAction(
            subject,
            isoFormat,
            isoFormatPriority,
            owner,
            status,
            reminder,
            repeat,
            meetLink,
            description,
            userId,
            Dailogcomponent
          )
        );
        handleClose();
      }

      // Display ISO format as time
      // const isoTime = dayjs(isoFormat).format('HH:mm:ss');
      // console.log('ISO Format as Time:', isoTime);
    }

    if (Dailogcomponent === "callCreate") {
      const validateCallCreate = () => {
        const newErrors = {};
        if (subject.trim() === "") {
          newErrors.subject = "Subject is required";
        }

        if (
          typeof dueData !== "string" &&
          dueData.hour() === currentDate.hour() &&
          dueData.minute() === currentDate.minute()
        ) {
          newErrors.dueData = "Current time cannot be selected";
        }

        if (repeat.trim() === "") {
          newErrors.repeat = "Call to is required";
        }

        if (description.trim() === "") {
          newErrors.description = "Description is required";
        }

        setErrorData(newErrors);
        return Object.keys(newErrors).length === 0;
      };

      if (validateCallCreate()) {
        dispatch(
          followUpActivitiesAddActionAction(
            subject,
            dueData,
            priority,
            owner,
            status,
            reminder,
            repeat,
            meetLink,
            description,
            userId,
            Dailogcomponent
          )
        );
        handleClose();
      }
    }

    if (Dailogcomponent === "socialMedia") {
      dispatch(
        followUpActivitiesAddAction(
          description,
          meetLink,
          userId,
          "socialMedia"
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "attachment") {
      dispatch(
        followUpActivitiesAddAction(
          attachment,
          visaSummary,
          userId,
          "attachment"
        )
      );
      handleClose();
    }

    if (Dailogcomponent === "composeMail") {
      if (isValidEmail(emailTo)) {
        if (isValidEmail(emailCc)) {
          if (isValidEmail(emailCc)) {
            const contentState = editorState.getCurrentContent();
            const htmlContent = stateToHTML(contentState);

            console.log(htmlContent);
            dispatch(
              followUpComposeMailAction(
                emailTo,
                emailCc,
                bcc,
                subject,
                htmlContent,
                userId
              )
            );
            handleClose();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "invalid mail!"
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "invalid mail!"
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "invalid mail!"
        });
      }
    }

    if (Dailogcomponent === "holiday") {
      if (validate()) {
        dispatch(
          hrEmployeeHolidayCreateAction(
            holidaymonth,
            LeaveCount,
            Holidayname,
            HolidayDate
          )
        );
        dispatch(successTrueMsgAction(true));
        setNewDate(new Date());
        handleClose();
      }
    }

    if (Dailogcomponent === "holidayEdit") {
      dispatch(
        hrEmployeeHolidayCreateEditAction(
          Editholidaymonth,
          EditLeaveCount,
          EditHolidayname,
          EditHolidayDate,
          appliedApplicationId
        )
      );
      dispatch(successTrueMsgAction(false));
      handleClose();
    }

    if (Dailogcomponent === "depositPaid") {
      const validate = () => {
        let errors = {};
        if (formValue.currency === "") {
          errors.currency = "currency is required";
        }
        if (formValue.totalTutionFee === "") {
          errors.totalTutionFee = "Total tutionFee is required";
        }
        if (formValue.paidAmount === "") {
          errors.paidAmount = "paidAmount is required";
        }
        if (formValue.paymentDate === "") {
          errors.paymentDate = "paymentDate is required";
        }

        if (formValue.paymentTime === "") {
          errors.paymentTime = "paymentTime is required";
        }
        if (formValue.receipt === "") {
          errors.receipt = "receipt is required";
        }
        if (formValue.balanceAmount === "") {
          errors.balanceAmount = "balanceAmount is required";
        }
        if (formValue.remarks === "") {
          errors.remarks = "remarks is required";
        }

        setPaymentFeeFormError(errors);
        console.log(errors);
        return Object.keys(errors).length === 0;
      };

      if (validate()) {
        setFormValue((prevState) => ({
          ...prevState,
          ["totalFeeNeedtoPaid"]:
            Number(formValue.totalTutionFee) -
            Number(formValue.scholarShipAmount) -
            Number(formValue.discountAmount)
        }));

        const formData = new FormData();
        formData.append("currency", formValue.currency);
        formData.append("totalTutionFee", formValue.totalTutionFee);
        formData.append("scholarShipName", formValue.scholarShipName);
        formData.append("scholarShipAmount", formValue.scholarShipAmount);
        formData.append("discountAmount", formValue.discountAmount);
        formData.append(
          "totalFeeToBePaid",
          formValue.paidAmount
          // Number(formValue.totalTutionFee) -
          //   Number(formValue.scholarShipAmount) -
          //   Number(formValue.discountAmount)
        );
        formData.append("paidAmount", formValue.paidAmount);
        formData.append("paidDate", formValue.paymentDate);
        formData.append("paidTime", formValue.paymentTime);
        formData.append("receipt", formValue.receipt);
        formData.append("balanceAmount", formValue.balanceAmount);
        formData.append("remark", formValue.remarks);
        formData.append("leadID", userId);
        formData.append("aplicationID", appliedApplicationId);

        dispatch(applicationDepositeCreateAction(formData));
        dispatch(
          leadOwnerChangeAction(
            "",
            userId,
            "",
            "",
            "Deposit Paid",
            appliedApplicationId
          )
        );

        handleClose();
      }
    }
    if (Dailogcomponent === "counsilorNote") {
      const today = new Date();
      const day = today.getDate();
      const month = today.toLocaleString("default", { month: "long" });
      const year = today.getFullYear();

      const formattedDate = `${day} ${month} ${year}`;
      console.log(formattedDate, "formatedDateeeeeeeeeeeeeee");
      if (headerName === "Counsilor note") {
        dispatch(
          leadCounsilorNoteCreateAction(
            appliedApplicationId,
            note,
            formattedDate
          )
        );
      }
      if (headerName === "Telecaller note") {
        dispatch(
          leadTelecallerNoteCreateAction(
            appliedApplicationId,
            note,
            formattedDate
          )
        );
      }
      handleClose();
    }

    if (Dailogcomponent == "counsilorNoteView") {
      handleClose();
      handleFunction();
    }

    if (Dailogcomponent === "LeadDuplicateRejectReason") {
      dispatch(leadDuplicateRejectAction(appliedApplicationId, reason));
      handleClose();
    }

    if (Dailogcomponent === "refundPopup") {
      const validate = () => {
        let errors = {};
        if (refundCurrency === "") {
          errors.refundCurrency = "refund Currency is required";
        }
        if (refundAmount === "") {
          errors.refundAmount = "refund Amount is required";
        }

        setrefundError(errors);
        console.log(errors);
        return Object.keys(errors).length === 0;
      };
      if (validate()) {
        dispatch(
          leadRefundCreateAction(
            refundCurrency,
            refundAmount,
            leadID,
            appliedApplicationId
          )
        );
        dispatch(
          leadOwnerChangeAction(
            "",
            userId,
            "",
            "",
            "Refund In Progress",
            appliedApplicationId
          )
        );
      }
      handleClose();
    }

    if (Dailogcomponent === "editRefundPopup") {
      const validate = () => {
        let errors = {};
        if (refundCurrency === "") {
          errors.refundCurrency = "refund Currency is required";
        }
        if (refundAmount === "") {
          errors.refundAmount = "refund Amount is required";
        }

        setrefundError(errors);
        console.log(errors);
        return Object.keys(errors).length === 0;
      };
      if (validate()) {
        dispatch(
          leadRefundUpdateAction(refundCurrency, refundAmount, modalData?.id)
        );
        Swal.fire("Updated!", "Refund amount updated successfully.", "success");
      }
      handleClose();
    }
  };

  console.log(
    applicationDepositeCreateSuccess,
    "applicationDepositeCreateSuccessapplicationDepositeCreateSuccess"
  );

  // text editor section
  const handleStyleChange = (e) => {
    const newStyle = e.target.value;

    // Toggle the new style immediately
    const newEditorState = RichUtils.toggleInlineStyle(editorState, newStyle);

    setSelectedStyle(newStyle);
    setEditorState(newEditorState);
  };

  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const handleStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        `BG_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        `TEXT_COLOR_${color.toUpperCase()}`
      )
    );
  };
  const handleTextAlignment = (alignment) => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, `ALIGN_${alignment.toUpperCase()}`)
    );
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  console.log(branchManagerSuccess, "vkndkvndskjvnskv");
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            bottom:
              Dailogcomponent === "visaDetails"
                ? "19vh"
                : Dailogcomponent === "taskCreate"
                ? "8vh"
                : Dailogcomponent === "meetCreate" || "callCreate"
                ? "7vh"
                : "35vh",
            width: Dailogcomponent === "ZoomConfiguration" ? "100%" : "850px",
            maxWidth: width ? width : "585px",
            height: height ? height : "fit-content",
            maxHeight: height
              ? height
              : Dailogcomponent === "depositPaid"
              ? "650px"
              : "502px",
            marginTop: margintop ? margintop : "15%"
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom:
              Dailogcomponent === "Declaraton" ? "0.1px solid #DADBE4" : "none"
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ fontSize: "22px", fontWeight: 700 }}
          >
            {headerName}
          </DialogTitle>

          <RxCross1
            style={{
              width: Dailogcomponent === "Declaraton" ? "25px" : "30px",
              height: "auto",
              margin: "15px",
              cursor: "pointer"
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          sx={{
            pt: "0"
          }}
        >
          {Dailogcomponent === "visaDetails" && (
            <Box mt={1}>
              <SelectInputField
                label="Lead Status"
                handleSelectChange={(e) => setLeadStatus(e.target.value)}
                widthSize="80px"
                value={leadStatus}
                mapValue={["Visa Applied"]}
                mapMethod="leadStatus"
              />
              <Box mt={3}>
                <InputField
                  label="Visa applied date"
                  widthSize="98.4%"
                  handleChange={(e) => {
                    setAppliedDate(e.target.value);
                  }}
                  value={appliedDate}
                  InputType="date"
                />
              </Box>

              <Box mt={3}>
                <InputField
                  label="Visa payment date"
                  widthSize="98.4%"
                  handleChange={(e) => {
                    setPaymentDate(e.target.value);
                  }}
                  value={paymentDate}
                  InputType="date"
                />
              </Box>

              <Box mt={3}>
                <InputField
                  label="Card details"
                  widthSize="98.4%"
                  handleChange={(e) => {
                    setCardDetail(e.target.value);
                  }}
                  value={cardDetail}
                  InputType="text"
                />
              </Box>

              <Box mt={3}>
                <InputField
                  label=" Receipt no"
                  widthSize="98.4%"
                  handleChange={(e) => {
                    setReceptNo(e.target.value);
                  }}
                  value={receptNo}
                  InputType="text"
                />
              </Box>
            </Box>
          )}

          {Dailogcomponent !== "university ID" &&
            Dailogcomponent !== "taskCreate" &&
            Dailogcomponent !== "callCreate" &&
            Dailogcomponent !== "visaDetails" &&
            Dailogcomponent !== "meetCreate" &&
            Dailogcomponent !== "socialMedia" &&
            Dailogcomponent !== "attachment" &&
            Dailogcomponent !== "composeMail" &&
            Dailogcomponent !== "holiday" &&
            Dailogcomponent !== "ZoomConfiguration" &&
            Dailogcomponent !== "holidayEdit" &&
            Dailogcomponent !== "LeaveApplicationForm" &&
            Dailogcomponent !== "Offer Letter Received" &&
            Dailogcomponent !== "contact" &&
            Dailogcomponent !== "Unconditional Offer" &&
            Dailogcomponent !== "Reason" &&
            Dailogcomponent !== "Declaraton" &&
            Dailogcomponent !== "declarationCompleted" &&
            Dailogcomponent !== "depositPaid" &&
            Dailogcomponent !== "tutionFee" &&
            Dailogcomponent !== "counsilorNote" &&
            Dailogcomponent !== "RejectReason" &&
            Dailogcomponent !== "statusApprovalReject" &&
            Dailogcomponent !== "counsilorNoteView" &&
            Dailogcomponent !== "LeadDuplicateRejectReason" &&
            Dailogcomponent !== "leadOwnerChangeApproval" &&
            Dailogcomponent !== "deferalReason" &&
            Dailogcomponent !== "refundPopup" &&
            Dailogcomponent !== "editRefundPopup" && (
              <FormControl
                fullWidth
                sx={{
                  mt: 1
                }}
              >
                <InputLabel
                  id="demo-simple-select-label1"
                  sx={{ color: "#000 !important" }}
                >
                  {headerName}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label1"
                  id="demo-simple-select1"
                  value={universityHandler}
                  label="Application Method"
                  sx={{
                    "& fieldset": {
                      display: "none"
                    },
                    borderBottom: "1px solid #000",
                    borderRadius: "0"
                  }}
                  onChange={(e) => {
                    setUniversityHandler(e.target.value);
                  }}
                >
                  {branchManagerSuccess &&
                    branchManagerSuccess?.map((data) => (
                      <MenuItem value={data.id}>{data.firstName}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

          {Dailogcomponent === "Reason" ||
          Dailogcomponent === "LeadDuplicateRejectReason" ||
          Dailogcomponent === "leadOwnerChangeApproval" ? (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <InputField
                label="Reason"
                handleChange={(e) => setReason(e.target.value)}
                widthSize="auto"
                value={reason}
                required
                paramses
              />
            </Box>
          ) : (
            ""
          )}

          {Dailogcomponent === "RejectReason" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <InputField
                label="Reason"
                handleChange={(e) => setReason(e.target.value)}
                widthSize="auto"
                value={reason}
                required
                paramses
              />
            </Box>
          )}

          {Dailogcomponent === "statusApprovalReject" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <InputField
                label="Reason"
                handleChange={(e) => setReason(e.target.value)}
                widthSize="auto"
                value={reason}
                required
                paramses
              />
            </Box>
          )}

          {Dailogcomponent === "deferalReason" && (
            <>
              <Box sx={{ mt: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={["month"]}
                    label="Month"
                    value={intakeMonth}
                    sx={{ width: 525 }}
                    onChange={(newValue) => setIntakeMonth(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <Typography>{error?.intakeMonth}</Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    views={["year"]}
                    label="Year"
                    value={intakeYear}
                    minDate={new Date()} // Restricts to the current year and future years
                    sx={{ width: 525 }}
                    onChange={(newValue) => setIntakeYear(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <Typography>{error?.intakeYear}</Typography>
              </Box>
            </>
          )}

          {Dailogcomponent === "Unconditional Offer" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <InputField
                label="university ID"
                handleChange={(e) => setUniversityId(e.target.value)}
                widthSize="auto"
                value={universityId}
                required
                paramses
              />

              <Box sx={{ mt: 2 }}>
                {leadAgreement ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() => setLeadAgreement(null)}
                    />
                    <Typography>{leadAgreement.name}</Typography>
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload Offer
                      Latter
                    </label>
                    <input
                      accept="pdf"
                      id="upload-company-logo"
                      type="file"
                      onChange={handleofferFileChange}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}

          {Dailogcomponent === "Offer Letter Received" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <InputField
                label="Student ID"
                handleChange={(e) => setUniversityId(e.target.value)}
                widthSize="auto"
                value={universityId}
                required
                paramses
              />

              <Box sx={{ mt: 2, mb: 2 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Conditonal"
                      control={
                        <Radio
                          sx={{
                            color: pink[800],
                            "&.Mui-checked": {
                              color: pink[600]
                            }
                          }}
                          onChange={(e) => setOfferLettertype(e.target.value)}
                        />
                      }
                      label="Conditional"
                    />
                    <FormControlLabel
                      value="Unconditional"
                      control={
                        <Radio
                          sx={{
                            color: pink[800],
                            "&.Mui-checked": {
                              color: pink[600]
                            }
                          }}
                          onChange={(e) => setOfferLettertype(e.target.value)}
                        />
                      }
                      label="Unconditional"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <SelectInputField
                label="Choose UniversityHandler"
                handleSelectChange={(e) => {
                  setUniversityHandler(e.target.value);
                }}
                widthSize="101px"
                value={universityHandler ? universityHandler : ""}
                mapValue={universityHandlerData}
                mapMethod="UniversityHandler"
              />
              {/* 
              <Box sx={{ mt: 3 }}>
                <SelectInputField
                  label="Choose Payment Status"
                  handleSelectChange={(e) => {
                    setPaymentStatus({
                      status: e.target.value,
                    });
                    setOfferRecieveDetails({
                      offerRecieveStatus: e.target.value,
                    });
                  }}
                  widthSize="101px"
                  // value={selectionCategory}
                  mapValue={["None", "Registration", "Pending", "Completed"]}
                  mapMethod=""
                />
              </Box>

              {paymentStatus.status === "Completed" ? (
                <>
                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Payment Date"
                      handleChange={(e) => setPaymentDate(e.target.value)}
                      // widthSize="auto"
                      value={paymentDate}
                      required
                      paramses
                      InputType="date"
                      widthSize="98%"
                    />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Registraton Date"
                      handleChange={(e) =>
                        setOfferRecieveDetails({
                          ...offerRecieveDetails,
                          registerDate: e.target.value,
                        })
                      }
                      // widthSize="auto"
                      value={offerRecieveDetails.registerDate}
                      required
                      paramses
                      InputType="date"
                      widthSize="98%"
                    />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Tution Fee"
                      handleChange={(e) =>
                        setOfferRecieveDetails({
                          ...offerRecieveDetails,
                          tutionFee: e.target.value,
                        })
                      }
                      // widthSize="auto"
                      value={offerRecieveDetails.tutionFee}
                      required
                      paramses
                      // InputType="date"
                      widthSize="98%"
                    />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Tution Fund Paid"
                      handleChange={(e) =>
                        setOfferRecieveDetails({
                          ...offerRecieveDetails,
                          tutionFund: e.target.value,
                        })
                      }
                      // widthSize="auto"
                      value={offerRecieveDetails.tutionFund}
                      required
                      paramses
                      // InputType="date"
                      widthSize="98%"
                    />
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Registration Amount"
                      handleChange={(e) =>
                        setOfferRecieveDetails({
                          ...offerRecieveDetails,
                          registrationAmmount: e.target.value,
                        })
                      }
                      // widthSize="auto"
                      value={offerRecieveDetails.registrationAmmount}
                      required
                      paramses
                      // InputType="date"
                      widthSize="98%"
                    />
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    {offerRecieveDetails.paySlip ? (
                      <>
                        <FcRemoveImage
                          style={{
                            fontSize: "16px",
                            width: "115px",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setOfferRecieveDetails({
                              ...offerRecieveDetails,
                              paySlip: null,
                            })
                          }
                        />
                        <Typography>
                          {offerRecieveDetails.paySlip.fileName}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <label
                          for="upload-company-logo"
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                            backgroundColor: "#141E3C",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            transition: "background-color 0.3s ease",
                            fontSize: "14px",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>+</span> Upload
                          Payment Slip
                        </label>
                        <input
                          accept="pdf"
                          id="upload-company-logo"
                          type="file"
                          onChange={(e) => {
                            const filePromise = new Promise(
                              (resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onloadend = () => resolve(reader.result);
                                reader.onerror = (error) => reject(error);
                              }
                            );

                            filePromise
                              .then((base64Array) => {
                                console.log(base64Array);
                                // setVisaSummary(base64Array);
                                setOfferRecieveDetails({
                                  ...offerRecieveDetails,
                                  paySlip: base64Array,
                                });
                              })
                              .catch((error) => {
                                console.log(
                                  "Error converting files to base64 : ",
                                  error
                                );
                              });
                          }}
                          style={{ display: "none" }}
                        />
                      </>
                    )}
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Source of Funding"
                      handleChange={(e) =>
                        setOfferRecieveDetails({
                          ...offerRecieveDetails,
                          sourceOfFunding: e.target.value,
                        })
                      }
                      // widthSize="auto"
                      value={offerRecieveDetails.sourceOfFunding}
                      required
                      paramses
                      // InputType="date"
                      widthSize="98%"
                    />
                  </Box>
                </>
              ) : paymentStatus.status === "Pending" ? (
                <>
                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Payment Date"
                      handleChange={(e) => setPaymentDate(e.target.value)}
                      // widthSize="auto"
                      value={paymentDate}
                      required
                      paramses
                      InputType="date"
                      widthSize="98%"
                    />
                  </Box>
                </>
              ) : paymentStatus.status === "Registration" ? (
                <>
                  <Box sx={{ mt: 3 }}>
                    <InputField
                      label="Registraton Date"
                      handleChange={(e) =>
                        setOfferRecieveDetails({
                          ...offerRecieveDetails,
                          registerDate: e.target.value,
                        })
                      }
                      // widthSize="auto"
                      value={offerRecieveDetails.registerDate}
                      required
                      paramses
                      InputType="date"
                      widthSize="98%"
                    />
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    {offerRecieveDetails.registerDoc ? (
                      <>
                        <FcRemoveImage
                          style={{
                            fontSize: "16px",
                            width: "115px",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setOfferRecieveDetails({
                              ...offerRecieveDetails,
                              registerDoc: null,
                            })
                          }
                        />
                        <Typography>
                          {offerRecieveDetails.registerDoc.fileName}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <label
                          for="upload-company-logo"
                          style={{
                            display: "inline-block",
                            cursor: "pointer",
                            backgroundColor: "#141E3C",
                            color: "#fff",
                            padding: "10px 20px",
                            borderRadius: "4px",
                            fontWeight: "bold",
                            transition: "background-color 0.3s ease",
                            fontSize: "14px",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>+</span> Upload
                          Register Document
                        </label>
                        <input
                          accept="pdf"
                          id="upload-company-logo"
                          type="file"
                          onChange={(e) => {
                            const filePromise = new Promise(
                              (resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onloadend = () => resolve(reader.result);
                                reader.onerror = (error) => reject(error);
                              }
                            );

                            filePromise
                              .then((base64Array) => {
                                console.log(base64Array);
                                // setVisaSummary(base64Array);
                                setOfferRecieveDetails({
                                  ...offerRecieveDetails,
                                  registerDoc: base64Array,
                                });
                              })
                              .catch((error) => {
                                console.log(
                                  "Error converting files to base64 : ",
                                  error
                                );
                              });
                          }}
                          style={{ display: "none" }}
                        />
                      </>
                    )}
                  </Box>
                </>
              ) : null} */}

              <Box sx={{ mt: 2 }}>
                {leadAgreement ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() => setLeadAgreement(null)}
                    />
                    <Typography>{leadAgreement.name}</Typography>
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload Offer
                      Letter
                    </label>
                    <input
                      accept="pdf"
                      id="upload-company-logo"
                      type="file"
                      onChange={handleofferFileChange}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
              <Typography sx={{ color: "#FF2400", fontSize: "12px" }}>
                {offerLetterError}
              </Typography>
            </Box>
          )}

          {Dailogcomponent === "depositPaid" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <Box>
                <SelectInputField
                  label="Currency"
                  handleSelectChange={(e) =>
                    setFormValue((prevState) => ({
                      ...prevState,
                      ["currency"]: e.target.value
                    }))
                  }
                  widthSize="32px"
                  value={formValue.currency}
                  mapValue={symbolArray}
                  mapMethod="currencyList"
                  name="currency"
                  height="50px"
                  requiredSymbol={true}
                />
                <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {paymentFeeFormError?.currency}
                </span>
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputField
                  label="Total Tution fee"
                  handleChange={(e) =>
                    setFormValue((prevState) => ({
                      ...prevState,
                      ["totalTutionFee"]: e.target.value
                    }))
                  }
                  widthSize="auto"
                  value={formValue.totalTutionFee}
                  required
                  paramses
                  error={Boolean(paymentFeeFormError?.totalTutionFee)}
                  helperText={paymentFeeFormError?.totalTutionFee}
                />
                {/* <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px",
                  }}
                >
                  {paymentFeeFormError?.totalTutionFee}
                </span> */}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={scholarShip}
                    onChange={(e) => {
                      setScholarShip(!scholarShip);
                    }}
                  />
                }
                label={"Scholarship"}
              />
              {scholarShip && (
                <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                  <Grid md={6} sx={{ mr: 2 }}>
                    <InputField
                      label="ScholarShip Name"
                      handleChange={(e) =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["scholarShipName"]: e.target.value
                        }))
                      }
                      widthSize="auto"
                      value={formValue.scholarShipName}
                      required
                      paramses
                    />
                  </Grid>
                  <Grid md={6}>
                    <InputField
                      label="ScholarShip Amount"
                      handleChange={(e) =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["scholarShipAmount"]: e.target.value
                        }))
                      }
                      widthSize="auto"
                      value={formValue.scholarShipAmount}
                      required
                      paramses
                    />
                  </Grid>
                </Box>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={discount}
                    onChange={(e) => {
                      setDiscount(!discount);
                    }}
                  />
                }
                label={"Discount"}
              />
              {discount && (
                <InputField
                  label="Discount amount"
                  handleChange={(e) =>
                    setFormValue((prevState) => ({
                      ...prevState,
                      ["discountAmount"]: e.target.value
                    }))
                  }
                  widthSize="auto"
                  value={formValue.discountAmount}
                  required
                  paramses
                />
              )}

              <Box>
                <Typography>
                  Total fee to be paid :
                  <span>
                    {Number(formValue.totalTutionFee) -
                      Number(formValue.scholarShipAmount) -
                      Number(formValue.discountAmount)}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 2 }}>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Paid Amount"
                    handleChange={(e) => {
                      const { value } = e.target;
                      setFormValue((prevState) => {
                        const paidAmount = Number(value); // Get the paid amount from the event
                        const totalTutionFee = Number(prevState.totalTutionFee);
                        const scholarShipAmount = Number(
                          prevState.scholarShipAmount
                        );
                        const discountAmount = Number(prevState.discountAmount);

                        // Calculate the new balance amount
                        const balanceAmount =
                          totalTutionFee -
                          scholarShipAmount -
                          discountAmount -
                          paidAmount;
                        setBalanceAmount(balanceAmount);
                        return {
                          ...prevState,
                          paidAmount: value,
                          balanceAmount
                        };
                      });
                    }}
                    widthSize="auto"
                    value={formValue.paidAmount}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.paidAmount)}
                    helperText={paymentFeeFormError?.paidAmount}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.paidAmount}
                  </span> */}
                </Grid>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    handleChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const today = new Date();
                      selectedDate.setHours(0, 0, 0, 0);
                      today.setHours(0, 0, 0, 0);

                      console.log(today, "today (normalized)");
                      console.log(selectedDate, "selectedDate (normalized)");

                      if (selectedDate > today) {
                        setPaymentFeeFormError((prevError) => ({
                          ...prevError,
                          paymentDate: "Future dates are not allowed"
                        }));
                      } else {
                        setPaymentFeeFormError((prevError) => ({
                          ...prevError,
                          paymentDate: ""
                        }));
                        setFormValue((prevState) => ({
                          ...prevState,
                          paymentDate: e.target.value
                        }));
                      }
                    }}
                    widthSize="auto"
                    value={formValue.paymentDate}
                    InputType="date"
                    error={Boolean(paymentFeeFormError?.paymentDate)}
                    helperText={paymentFeeFormError?.paymentDate}
                  />
                </Grid>

                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Time"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["paymentTime"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.paymentTime}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.paymentTime)}
                    helperText={paymentFeeFormError?.paymentTime}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.paymentTime}
                  </span> */}

                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      value={formValue.paymentTime}
                      onChange={(newValue) =>
                        setFormValue({ paymentTime: newValue })
                      }
                      label="Time"
                      // sx={{
                      //   textAlign: "center",
                      //   backgroundColor: "white",
                      //   borderRadius: "4px",
                      //   height: "49px",
                      //   border: "2px solid white",
                      //   // "& fieldset": {
                      //   //   border: "2px solid black",
                      //   //   borderRadius: "9px",
                      //   // },
                      //   // // height: "auto",
                      //   "& .MuiFormLabel-root": {
                      //     height: "30px",
                      //     pt: 1,
                      //     border: "2px solid white",

                      //     // pb:2,

                      //   //   "&.Mui-focused": {
                      //   //     color: "#7d7d7d",
                      //   //     pt: 0.9,
                      //   //   },
                      //   },
                      // }}
                    />
                  </LocalizationProvider> */}
                </Grid>
              </Box>
              {balaceAmountAdd !== "" &&
                (balaceAmountAdd <= 0 ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A"
                          }
                        }}
                        checked={true}
                        // onChange={(e) => {
                        //   setDiscount(!discount);
                        // }}
                      />
                    }
                    label={"Completed"}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A"
                          }
                        }}
                        checked={true}
                        // onChange={(e) => {
                        //   setDiscount(!discount);
                        // }}
                      />
                    }
                    label={"Deposite"}
                  />
                ))}

              <Box>
                <Typography>Receipt</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                {formValue.receipt ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["receipt"]: ""
                        }))
                      }
                    />
                    {/* <Typography>{leadAgreement.name}</Typography> */}
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload
                      Receipt
                    </label>
                    <input
                      accept="pdf"
                      id="upload-company-logo"
                      type="file"
                      onChange={(e) =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["receipt"]: e.target.files[0]
                        }))
                      }
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
              <span
                style={{
                  color: "#FF2400",
                  fontSize: "12px"
                }}
              >
                {paymentFeeFormError?.receipt}
              </span>

              <Box sx={{ display: "flex", mt: 2 }}>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Balance Amount"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["balanceAmount"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.balanceAmount}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.balanceAmount)}
                    helperText={paymentFeeFormError?.balanceAmount}
                    readonly={true}
                  />
                  {/* 
                  <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.balanceAmount}
                  </span> */}
                </Grid>
                <Grid md={8} sx={{ mr: 2 }}>
                  <InputField
                    label="Remark"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["remarks"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.remarks}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.remarks)}
                    helperText={paymentFeeFormError?.remarks}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.remarks}
                  </span> */}
                </Grid>
              </Box>
            </Box>
          )}

          {Dailogcomponent === "refundPopup" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <Box>
                <SelectInputField
                  label="Currency"
                  handleSelectChange={(e) => setRefundCurrency(e.target.value)}
                  widthSize="32px"
                  value={refundCurrency}
                  mapValue={symbolArray}
                  mapMethod="currencyList"
                  name="currency"
                  height="50px"
                  requiredSymbol={true}
                />
                <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {refundError?.refundCurrency}
                </span>
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputField
                  label="Refund amount"
                  handleChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      setRefundAmount(e.target.value);
                    }
                  }}
                  widthSize="auto"
                  value={refundAmount}
                  required
                  paramses
                  error={Boolean(refundError?.refundAmount)}
                  helperText={refundError?.refundAmount}
                />
              </Box>
            </Box>
          )}

          {Dailogcomponent === "editRefundPopup" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <Box>
                <SelectInputField
                  label="Currency"
                  handleSelectChange={(e) => setRefundCurrency(e.target.value)}
                  widthSize="32px"
                  value={refundCurrency}
                  mapValue={symbolArray}
                  mapMethod="currencyList"
                  name="currency"
                  height="50px"
                  requiredSymbol={true}
                />
                <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {refundError?.refundCurrency}
                </span>
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputField
                  label="Refund amount"
                  handleChange={(e) => {
                    if (/^\d*\.?\d*$/.test(e.target.value)) {
                      setRefundAmount(e.target.value);
                    }
                  }}
                  widthSize="auto"
                  value={refundAmount}
                  required
                  paramses
                  error={Boolean(refundError?.refundAmount)}
                  helperText={refundError?.refundAmount}
                />
              </Box>
            </Box>
          )}

          {Dailogcomponent === "tutionFee" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 400, fontSize: "20px", mb: 2 }}>
                  Total tution fee :<span>{}</span>
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "20px" }}>
                  Amount paid :<span></span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 2 }}>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Paid Amount"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["paidAmount"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.paidAmount}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.paidAmount)}
                    helperText={paymentFeeFormError?.paidAmount}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.paidAmount}
                  </span> */}
                </Grid>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["paymentDate"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.paymentDate}
                    // required
                    paramses
                    InputType="date"
                    error={Boolean(paymentFeeFormError?.paymentDate)}
                    helperText={paymentFeeFormError?.paymentDate}
                  />
                </Grid>
                <Grid md={4} sx={{ mr: 2 }}>
                  <InputField
                    label="Time"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["paymentTime"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.paymentTime}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.paymentTime)}
                    helperText={paymentFeeFormError?.paymentTime}
                  />
                </Grid>
              </Box>

              <Box>
                <Typography>Receipt</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                {formValue.receipt ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["receipt"]: ""
                        }))
                      }
                    />
                    {/* <Typography>{leadAgreement.name}</Typography> */}
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload
                      Receipt
                    </label>
                    <input
                      accept="pdf"
                      id="upload-company-logo"
                      type="file"
                      onChange={(e) =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          ["receipt"]: e.target.files[0]
                        }))
                      }
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
              <span
                style={{
                  color: "#FF2400",
                  fontSize: "12px"
                }}
              >
                {paymentFeeFormError?.receipt}
              </span>

              <Box sx={{ mt: 2 }}>
                <Grid md={12} sx={{ mr: 2 }}>
                  <InputField
                    label="Remark"
                    handleChange={(e) =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        ["remarks"]: e.target.value
                      }))
                    }
                    widthSize="auto"
                    value={formValue.remarks}
                    required
                    paramses
                    error={Boolean(paymentFeeFormError?.remarks)}
                    helperText={paymentFeeFormError?.remarks}
                  />
                  {/* <span
                    style={{
                      color: "#FF2400",
                      fontSize: "12px",
                    }}
                  >
                    {paymentFeeFormError?.remarks}
                  </span> */}
                </Grid>
              </Box>
            </Box>
          )}

          {/* visa in progress */}
          {Dailogcomponent === "university ID" && (
            <Box sx={{ mt: 1 }}>
              {/* <InputField
                label="university ID"
                handleChange={(e) => setUniversityId(e.target.value)}
                widthSize="auto"
                value={universityId}
                required
                paramses
              /> */}

              <Box sx={{ mt: 2 }}>
                {leadAgreement ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() => setLeadAgreement(null)}
                    />
                    <Typography>{leadAgreement.name}</Typography>
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload Lead
                      Agreement
                    </label>
                    <input
                      accept="pdf"
                      id="upload-company-logo"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}

          {Dailogcomponent === "taskCreate" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <InputField
                  label="Subject"
                  handleChange={(e) => setSubject(e.target.value)}
                  widthSize="100%"
                  value={subject}
                  // required
                  paramses
                  error={Boolean(errorData.subject)}
                  helperText={errorData.subject}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <InputField
                  label="Due date"
                  handleChange={(e) => setDueData(e.target.value)}
                  widthSize="100%"
                  value={dueData}
                  // required
                  paramses
                  InputType="date"
                  error={Boolean(errorData.dueData)}
                  helperText={errorData.dueData}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Priority"
                  handleChange={(e) => setPriority(e.target.value)}
                  widthSize="100%"
                  value={priority}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Owner"
                  handleChange={(e) => setOwner(e.target.value)}
                  widthSize="100%"
                  value={owner}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Status"
                  handleChange={(e) => setStatus(e.target.value)}
                  widthSize="100%"
                  value={status}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Reminder"
                  handleChange={(e) => setReminder(e.target.value)}
                  widthSize="100%"
                  value={reminder}
                  InputType="date"
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Repeat"
                  handleChange={(e) => setRepeat(e.target.value)}
                  widthSize="100%"
                  value={repeat}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label="Description"
                  handleChange={(e) => setDescription(e.target.value)}
                  widthSize="100%"
                  value={description}
                  required
                  paramses
                  InputType="discription"
                  dispcriptionHeight="150px"
                  error={Boolean(errorData.description)}
                  helperText={errorData.description}
                />
              </Grid>
            </Grid>
          )}

          {Dailogcomponent === "meetCreate" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <InputField
                  label="Subject"
                  handleChange={(e) => setSubject(e.target.value)}
                  widthSize="100%"
                  value={subject}
                  // required
                  paramses
                  error={Boolean(errorData.subject)}
                  helperText={errorData.subject}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                {/* <InputField
                  label="From"
                  handleChange={(e) => setDueData(e.target.value)}
                  widthSize="100%"
                  value={dueData}
                  // required
                  paramses
                  InputType="text"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <InputField
                    label="From"
                    widthSize="100%"
                    value={dueData.format("YYYY-MM-DDTHH:mm")}
                    InputType="text"
                  /> */}
                  <DemoItem label="From">
                    <MobileTimePicker
                      value={dueData}
                      onChange={(newValue) => setDueData(newValue)}
                    />
                  </DemoItem>
                </LocalizationProvider>
                {Boolean(errorData?.dueData) && (
                  <Box
                    sx={{
                      ml: "4px"
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FF2400",
                        fontSize: "12px"
                      }}
                    >
                      {errorData?.dueData}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <InputField
                  label="To"
                  handleChange={(e) => setPriority(e.target.value)}
                  widthSize="100%"
                  value={priority}
                  // required
                  paramses
                /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem label="To">
                    <MobileTimePicker
                      value={priority}
                      onChange={(newValue) => setPriority(newValue)}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} lg={6}>
                <InputField
                  label="Location"
                  handleChange={(e) => setOwner(e.target.value)}
                  widthSize="100%"
                  value={owner}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Host"
                  handleChange={(e) => setRepeat(e.target.value)}
                  widthSize="100%"
                  value={repeat}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Related to"
                  handleChange={(e) => setReminder(e.target.value)}
                  widthSize="100%"
                  value={reminder}
                  // InputType="date"
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Status"
                  handleChange={(e) => setStatus(e.target.value)}
                  widthSize="100%"
                  value={status}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <InputField
                  label="Meeting Link"
                  handleChange={(e) => setMeetLink(e.target.value)}
                  widthSize="100%"
                  value={meetLink}
                  // required
                  paramses
                  error={Boolean(errorData.meetLink)}
                  helperText={errorData.meetLink}
                />
              </Grid>

              <Grid item xs={12}>
                <InputField
                  label="Description"
                  handleChange={(e) => setDescription(e.target.value)}
                  widthSize="100%"
                  value={description}
                  required
                  paramses
                  InputType="discription"
                  dispcriptionHeight="150px"
                  error={Boolean(errorData.description)}
                  helperText={errorData.description}
                />
              </Grid>
            </Grid>
          )}

          {Dailogcomponent === "callCreate" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <InputField
                  label="Subject"
                  handleChange={(e) => setSubject(e.target.value)}
                  widthSize="100%"
                  value={subject}
                  // required
                  paramses
                  error={Boolean(errorData.subject)}
                  helperText={errorData.subject}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                {/* <InputField
                  label="Call start time"
                  handleChange={(e) => setDueData(e.target.value)}
                  widthSize="100%"
                  value={dueData}
                  // required
                  paramses
                  InputType="text"
                /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <InputField
                    label="From"
                    widthSize="100%"
                    value={dueData.format("YYYY-MM-DDTHH:mm")}
                    InputType="text"
                  /> */}
                  <DemoItem label="Call start time">
                    <MobileTimePicker
                      style={{
                        width: "100%",
                        height: "auto"
                      }}
                      value={dueData}
                      onChange={(newValue) => setDueData(newValue)}
                    />
                  </DemoItem>
                </LocalizationProvider>

                {Boolean(errorData?.dueData) && (
                  <Box
                    sx={{
                      ml: "4px"
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FF2400",
                        fontSize: "12px"
                      }}
                    >
                      {errorData?.dueData}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Call owner"
                  handleChange={(e) => setOwner(e.target.value)}
                  widthSize="100%"
                  value={owner}
                  // required
                  paramses
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <InputField
                  label="Call type"
                  handleChange={(e) => setPriority(e.target.value)}
                  widthSize="100%"
                  value={priority}
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Call to"
                  handleChange={(e) => setRepeat(e.target.value)}
                  widthSize="100%"
                  value={repeat}
                  // required
                  paramses
                  error={Boolean(errorData.repeat)}
                  helperText={errorData.repeat}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <InputField
                  label="Reminder"
                  handleChange={(e) => setReminder(e.target.value)}
                  widthSize="100%"
                  value={reminder}
                  // InputType="date"
                  // required
                  paramses
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Status"
                  handleChange={(e) => setStatus(e.target.value)}
                  widthSize="100%"
                  value={status}
                  // required
                  paramses
                />
              </Grid>

              <Grid item xs={12}>
                <InputField
                  label="Description"
                  handleChange={(e) => setDescription(e.target.value)}
                  widthSize="100%"
                  value={description}
                  required
                  paramses
                  InputType="discription"
                  dispcriptionHeight="150px"
                  error={Boolean(errorData.description)}
                  helperText={errorData.description}
                />
              </Grid>
            </Grid>
          )}

          {Dailogcomponent === "socialMedia" && (
            <>
              <Grid item xs={12} mt={1}>
                <InputField
                  label="Social Link"
                  handleChange={(e) => setDescription(e.target.value)}
                  widthSize="97.3%"
                  value={description}
                  required
                  paramses
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <SelectInputField
                  label="Meet Link"
                  handleSelectChange={(e) => setMeetLink(e.target.value)}
                  widthSize="65px"
                  value={meetLink}
                  mapValue={["FaceBook", "Instagram"]}
                  mapMethod="leadStatus"
                />
              </Grid>
            </>
          )}

          {Dailogcomponent === "attachment" && (
            <Box sx={{ mt: 1 }}>
              <InputField
                label="Attachment Name"
                handleChange={(e) => setAttachment(e.target.value)}
                widthSize="100%"
                value={attachment}
                required
                paramses
              />

              <Box sx={{ mt: 2, textAlign: "center" }}>
                {leadAgreement ? (
                  <>
                    <FcRemoveImage
                      style={{
                        fontSize: "16px",
                        width: "115px",
                        height: "auto",
                        cursor: "pointer"
                      }}
                      onClick={() => setLeadAgreement(null)}
                    />
                    <Typography>{leadAgreement.name}</Typography>
                  </>
                ) : (
                  <>
                    <label
                      for="upload-company-logo"
                      style={{
                        display: "inline-block",
                        cursor: "pointer",
                        backgroundColor: "#141E3C",
                        color: "#fff",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        transition: "background-color 0.3s ease",
                        fontSize: "14px"
                      }}
                    >
                      <span style={{ marginRight: "5px" }}>+</span> Upload
                      Attachment
                    </label>
                    <input
                      accept="image/*"
                      id="upload-company-logo"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </Box>
            </Box>
          )}

          {Dailogcomponent === "composeMail" && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <InputField
                    label="To"
                    handleChange={(e) => setEmailTo(e.target.value)}
                    widthSize="100%"
                    value={emailTo}
                    // required
                    paramses
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <InputField
                    label="cc"
                    handleChange={(e) => setEmailCc(e.target.value)}
                    widthSize="100%"
                    value={emailCc}
                    // required
                    paramses
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <InputField
                    label="Bcc"
                    handleChange={(e) => setBcc(e.target.value)}
                    widthSize="100%"
                    value={bcc}
                    // required
                    paramses
                    InputType="text"
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  <InputField
                    label="Subject"
                    handleChange={(e) => setSubject(e.target.value)}
                    widthSize="100%"
                    value={subject}
                    // required
                    paramses
                  />
                </Grid>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  <div>
                    <ToggleButtonGroup
                      value={formats}
                      onChange={handleFormat}
                      aria-label="text formatting"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <select
                        value={selectedStyle}
                        onChange={handleStyleChange}
                      >
                        <option value="NORMAL">Normal</option>
                        <option value="BOLD">Bold</option>
                        <option value="ITALIC">Italic</option>
                        <option value="UNDERLINE">Underline</option>
                      </select>
                      <FormatBoldIcon
                        onClick={handleBoldClick}
                        value="bold"
                        aria-label="bold"
                      />

                      <FormatItalicIcon
                        onClick={handleItalicClick}
                        value="italic"
                        aria-label="italic"
                      />

                      <FormatUnderlinedIcon
                        onClick={handleUnderlineClick}
                        value="underlined"
                        aria-label="underlined"
                      />

                      <StrikethroughSIcon
                        onClick={handleStrikethroughClick}
                        value="strikethrough"
                        aria-label="strikethrough"
                      />
                      <FormatColorTextIcon
                        onClick={() => handleBgColorChange("yellow")}
                        value="backgroundcolor"
                        aria-label="backgroundcolor"
                      />

                      <InvertColorsIcon
                        onClick={() => handleTextColorChange("blue")}
                        value="textcolor"
                        aria-label="textcolor"
                      />

                      <FormatAlignLeftIcon
                        onClick={() => handleTextAlignment("left")}
                        value="textcolor"
                        aria-label="textcolor"
                      />
                    </ToggleButtonGroup>
                  </div>
                  <div
                    style={{
                      border: "1px solid #ececec",
                      padding: "10px",
                      minHeight: "200px",
                      marginTop: "10px"
                    }}
                  >
                    <Editor
                      editorState={editorState}
                      onChange={setEditorState}
                      customStyleMap={{
                        // Define custom inline styles for text and background colors
                        TEXT_COLOR_RED: { color: "red" },
                        TEXT_COLOR_BLUE: { color: "blue" },
                        BG_COLOR_YELLOW: { backgroundColor: "yellow" },
                        BG_COLOR_GREEN: { backgroundColor: "green" },
                        ALIGN_LEFT: { textAlign: "left" }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* holiday Modal */}

          {Dailogcomponent === "holiday" && (
            <Box
              sx={{
                "& select": {
                  width: "100%"
                },
                "& input": {
                  width: "100%",
                  maxWidth: "calc(100% - 10px) !important"
                },
                "& .input-group": {
                  marginBottom: "0px"
                },
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mb: "20px",
                mt: "8px"
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    lg: "1fr 1fr"
                  },
                  gap: "20px"
                }}
              >
                <Box>
                  <SelectInputField
                    label="Select Month"
                    handleSelectChange={(e) => {
                      SetHolidayMonth(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        ["holidaymonth"]: ""
                      }));
                    }}
                    widthSize="90px"
                    value={holidaymonth}
                    mapValue={data}
                    mapMethod="holiday"
                  />
                  {Boolean(errors?.holidaymonth) && (
                    <Box
                      sx={{
                        mt: "4px",
                        ml: "4px",
                        textAlign: "left"
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#FF2400",
                          fontSize: "12px"
                        }}
                      >
                        {errors?.holidaymonth}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box>
                  <InputField
                    label="Total Working Days"
                    handleChange={(e) => {
                      setLeaveCount(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        ["LeaveCount"]: ""
                      }));
                    }}
                    widthSize="100%"
                    value={LeaveCount}
                    InputType="number"
                    required
                    paramses
                    error={Boolean(errors?.LeaveCount)}
                    helperText={errors?.LeaveCount}
                  />
                </Box>
              </Box>

              <Box>
                <InputField
                  label="Holiday Name"
                  handleChange={(e) => {
                    setHolidayname(e.target.value);
                    setErrors((prev) => ({
                      ...prev,
                      ["Holidayname"]: ""
                    }));
                  }}
                  widthSize="100%"
                  value={Holidayname}
                  // required
                  paramses
                  error={Boolean(errors?.Holidayname)}
                  helperText={errors?.Holidayname}
                />
              </Box>

              <Box>
                <InputField
                  label="Date"
                  handleChange={(e) => {
                    setHolidayDate(e.target.value);
                    setErrors((prev) => ({
                      ...prev,
                      ["HolidayDate"]: ""
                    }));
                  }}
                  widthSize="100%"
                  value={HolidayDate}
                  InputType="date"
                  // required
                  paramses
                  error={Boolean(errors?.HolidayDate)}
                  helperText={errors?.HolidayDate}
                />
              </Box>
            </Box>
          )}

          {Dailogcomponent === "holidayEdit" && (
            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} lg={6}>
                <SelectInputField
                  label="Select Month"
                  handleSelectChange={(e) =>
                    SetEditHolidayMonth(e.target.value)
                  }
                  widthSize="90px"
                  value={Editholidaymonth}
                  mapValue={data}
                  mapMethod="holiday"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputField
                  label="Total Working Days"
                  handleChange={(e) => setEditLeaveCount(e.target.value)}
                  widthSize="100%"
                  value={EditLeaveCount}
                  required
                  paramses
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <InputField
                  label="Holiday Name"
                  handleChange={(e) => setEditHolidayname(e.target.value)}
                  widthSize="100%"
                  value={EditHolidayname}
                  // required
                  paramses
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <InputField
                  label="Date"
                  handleChange={(e) => setEditHolidayDate(e.target.value)}
                  widthSize="100%"
                  value={EditHolidayDate}
                  InputType="date"
                  // required
                  paramses
                />
              </Grid>
            </Grid>
          )}

          {/* Leave Application modal */}

          {Dailogcomponent === "LeaveApplicationForm" && (
            <>
              <form onSubmit={handleSubmit}>
                {/* <InfoHeader
                  headTitle="Apply Leave"
                  url={`/Administration/employee/details/${id}`}
                /> */}

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {/* First Column */}
                  <Grid item xs={12} sm={4}>
                    <SelectInputField
                      label="Employee ID"
                      handleSelectChange={handleEmployeeIdField}
                      widthSize="75px"
                      value={employeeId}
                      mapValue={allEmployees}
                      mapMethod="employeeId"
                    />
                    {Boolean(error.employeeId) && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#FF2400"
                        }}
                      >
                        {error.employeeId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputField
                      label="Employee Mail"
                      // handleChange={(e) => setMailId(e.target.value)}
                      value={mailId}
                      widthSize="99%"
                      handleChange={(e) => {
                        setMailId(e.target.value);
                        setError((prev) => ({
                          ...prev,
                          ["mailId"]: ""
                        }));
                      }}
                      error={Boolean(error.mailId)}
                      helperText={error.mailId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInputField
                      label="Leave Type"
                      handleSelectChange={(e) => setLeaveType(e.target.value)}
                      widthSize="75px"
                      value={leaveType}
                      mapValue={type}
                      mapMethod="leaveType"
                    />
                  </Grid>

                  {/* Second Column */}
                  <Grid item lg={12} xs={12} sm={12}>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A"
                          },
                          fontSize: "12px",
                          fontWeight: 400
                        }}
                        checked={isFullDay}
                        onChange={(e) => setIsFullDay(e.target.checked)}
                      />
                      <Typography sx={{ mt: 1 }}>Full Day</Typography>
                    </div>
                  </Grid>

                  {/* Third Column */}
                  <Grid item xs={12} sm={12} lg={6}>
                    <InputField
                      label="Date From"
                      handleChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const today = new Date();
                        if (selectedDate < today) {
                          setDateFrom(today.toISOString().split("T")[0]);
                        } else {
                          setDateFrom(e.target.value);
                        }
                      }}
                      value={dateFrom}
                      widthSize="99%"
                      InputType="date"
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <InputField
                      label="Date To"
                      handleChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const today = new Date();
                        if (
                          selectedDate < today ||
                          selectedDate < new Date(dateFrom)
                        ) {
                          setError((prev) => ({
                            ...prev,
                            ["dateTo"]: "select date greater than From date"
                          }));
                        } else {
                          setToDateError("");
                          setDateTo(e.target.value);
                          setError((prev) => ({
                            ...prev,
                            ["dateTo"]: ""
                          }));
                        }
                      }}
                      value={dateTo}
                      widthSize="99%"
                      InputType="date"
                    />
                    {Boolean(error.dateTo) && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "-15px",
                          color: "#FF2400"
                        }}
                      >
                        {error.dateTo}
                      </Typography>
                    )}
                    {/* <span
                      style={{
                        color: "red",
                        fontSize: "13px",
                        fontWeight: 350,
                      }}
                    >
                      {toDateError}
                    </span> */}
                  </Grid>

                  {/* Fourth Column */}
                  <Grid item xs={12} sm={12} lg={12}>
                    <InputField
                      label="Leave Reason"
                      handleChange={(e) => setLeaveReason(e.target.value)}
                      value={leaveReason}
                      widthSize="99%"
                      InputType="discription"
                      dispcriptionHeight="150px"
                    />
                  </Grid>
                </Grid>

                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    id="demo-positioned-button"
                    style={{
                      backgroundColor: "#141E3C",
                      size: "10px",
                      padding: 1,
                      color: "#fff",
                      width: "80px",
                      height: "45px",
                      borderRadius: "5px",
                      margin: "8px",
                      fontWeight: 600,
                      fontSize: "13px"
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </>
          )}

          {Dailogcomponent === "counsilorNote" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              <InputField
                label="Enter note"
                handleChange={(e) => setNote(e.target.value)}
                widthSize="542px"
                dispcriptionHeight="218px"
                value={note}
                required
                paramses
                InputType={"discription"}
              />
            </Box>
          )}

          {Dailogcomponent === "counsilorNoteView" && (
            <Box
              sx={{
                mt: 1,
                "& .input_style": { maxWidth: "calc(100% - 10px)" }
              }}
            >
              {/* <InputField
                label="Enter note"
                handleChange={(e) => setNote(e.target.value)}
                widthSize="542px"
                dispcriptionHeight="218px"
                value={note}
                required
                paramses
                InputType={"discription"}
              /> */}

              <Table className="LeadTable">
                <TableHead>
                  <TableRow className="LeadTableCell">
                    {/* <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        padding: "1px",
                        border: "1px solid #ddd",
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <Checkbox
                        checked={selectedRows?.length === sortedData?.length}
                        onChange={handleSelectAllRows}
                        style={{ color: "#f81b82" }}
                      /> */}
                    {/* </TableCell> */}
                    {columns?.map((column) => {
                      if (column.hidden) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={column.field}
                          className="LeadTableCell LeadCommon"
                          style={{
                            padding: "0 16px",
                            whiteSpace: "nowrap",
                            textTransform: "uppercase",
                            fontWeight: "600"
                          }}
                        >
                          <TableSortLabel
                            className="LeadHeaderText"
                            // active={column === sortBy}
                            // direction={sortOrder}
                            // onClick={() => requestSort(column)}
                            // IconComponent={
                            //   column.field !== "action" && CustomSortIcon
                            // }
                          >
                            {column.title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modalData.map((item, indx) => (
                    <TableRow className="LeadTable" key={indx}>
                      {/* <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1, // To ensure it's above other cells
                          backgroundColor: "white", // Adjust as needed
                          padding: "1px",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Checkbox
                          checked={selectedRows.includes(indx)}
                          onChange={() => handleSelectRow(indx)}
                          style={{ color: "#f81b82" }}
                        />
                      </TableCell> */}
                      <TableCell
                        style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                      >
                        {indx + 1}
                      </TableCell>

                      <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap",
                          textTransform: "capitalize"
                        }}
                      >
                        {item.counsilorNote
                          ? item.counsilorNote
                          : item.teleCallerNote}
                      </TableCell>

                      <TableCell
                        style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                      >
                        {item.noteDate || " - "}
                      </TableCell>

                      <TableCell
                        style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                      >
                        {item?.CreatedBy}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          {Dailogcomponent !== "LeaveApplicationForm" &&
            Dailogcomponent !== "contact" &&
            Dailogcomponent !== "ZoomConfiguration" &&
            Dailogcomponent !== "Declaraton" &&
            Dailogcomponent !== "declarationCompleted" && (
              <DialogActions
                sx={{ mt: Dailogcomponent == "deferalReason" ? 2 : 0 }}
              >
                <SubmitButton
                  title={
                    Dailogcomponent == "holiday" ||
                    Dailogcomponent == "counsilorNoteView"
                      ? "Create"
                      : Dailogcomponent == "depositPaid"
                      ? "Submit for approval"
                      : "Update"
                  }
                  submit=""
                  widthSize={
                    Dailogcomponent == "depositPaid" ? "180px" : "72px"
                  }
                  heightSize="30px"
                  type="click"
                  handleSubmit={handleMethodApplyClick}
                />
              </DialogActions>
            )}

          {Dailogcomponent === "ZoomConfiguration" ? (
            <Box
              sx={{
                border: "1px solid #ECECEC"
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr "
                }}
              >
                <Box
                  sx={{
                    borderRight: "1px solid #ECECEC",
                    padding: "10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px"
                    }}
                  >
                    User Name
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6
                    }}
                  >
                    Admin
                  </Typography>
                </Box>

                <Box
                  sx={{
                    borderRight: "1px solid #ECECEC",
                    padding: "10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px"
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6
                    }}
                  >
                    {modalData?.email}
                  </Typography>
                </Box>

                {/* <Box
                  sx={{
                    borderRight: "1px solid #ECECEC",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    Meeting ID
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6,
                    }}
                  >
                    {modalData?.meetingId}
                  </Typography>
                </Box> */}

                <Box
                  sx={{
                    padding: "10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px"
                    }}
                  >
                    Host Key{" "}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6
                    }}
                  >
                    {modalData?.hostKey}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                  borderTop: "1px solid #ECECEC",
                  borderBottom: "1px solid #ECECEC"
                }}
              >
                <Box
                  sx={{
                    padding: "10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px"
                    }}
                  >
                    Zoom Link
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6
                    }}
                  >
                    {modalData?.joinUrl}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: "10px"
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "14px"
                    }}
                  >
                    Settings
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "12px"
                    }}
                  >
                    {modalData?.hostVideo ? (
                      <Typography
                        sx={{
                          padding: "4px 7px",
                          borderRadius: "4px",
                          backgroundColor: "#FE0B7A",
                          color: "#fff",
                          fontSize: "12px"
                        }}
                      >
                        Host video
                      </Typography>
                    ) : (
                      ""
                    )}
                    {modalData?.participantVideo ? (
                      <Typography
                        sx={{
                          padding: "4px 7px",
                          borderRadius: "4px",
                          backgroundColor: "#FE0B7A",
                          color: "#fff",
                          fontSize: "12px"
                        }}
                      >
                        Participant video
                      </Typography>
                    ) : (
                      ""
                    )}
                    {modalData?.waitingRoom ? (
                      <Typography
                        sx={{
                          padding: "4px 7px",
                          borderRadius: "4px",
                          backgroundColor: "#FE0B7A",
                          color: "#fff",
                          fontSize: "12px"
                        }}
                      >
                        Waiting Room
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>

              {/* <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 2fr 1fr 1fr",
                }}
              >
                <Box
                  sx={{
                    borderRight: "1px solid #ECECEC",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    User Name
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6,
                    }}
                  >
                    Admin
                  </Typography>
                </Box>

                <Box
                  sx={{
                    borderRight: "1px solid #ECECEC",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6,
                    }}
                  >
                    Admin@gmail.com
                  </Typography>
                </Box>

                <Box
                  sx={{
                    borderRight: "1px solid #ECECEC",
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    Meeting ID
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6,
                    }}
                  >
                    888 777 666
                  </Typography>
                </Box>

                <Box
                  sx={{
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    Host Key{" "}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6,
                    }}
                  >
                    899898
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          ) : (
            ""
          )}

          {Dailogcomponent === "contact" && (
            <Box>
              <Box
                sx={
                  {
                    // border: "1px solid #ECECEC",
                  }
                }
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    mb: "20px",
                    borderBottom: "1px solid #ECECEC"
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#000",
                        fontSize: "14px"
                      }}
                    >
                      User Name
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "12px",
                        opacity: 0.6
                      }}
                    >
                      {modalData?.fullName}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#000",
                        fontSize: "14px"
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "12px",
                        opacity: 0.6
                      }}
                    >
                      {modalData?.email}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    mb: "40px"
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000",
                      fontSize: "14px"
                    }}
                  >
                    Message
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "12px",
                      opacity: 0.6
                    }}
                  >
                    {modalData?.message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {Dailogcomponent === "Declaraton" && (
            <>
              <Box
                sx={{
                  display: "grid",
                  gap: "20px"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "'Poppins',sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    ml: 1,
                    mt: 2,
                    textAlign: "center"
                  }}
                >
                  Please upload the declaration document. The student will later
                  download, complete, and re-upload this document for submitting
                  their visa application.
                </Typography>

                {Boolean(!declarationFile) && (
                  <Box
                    sx={{
                      "& label": {
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        border: "1px solid #84848478",
                        padding: "10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        justifyContent: "center"
                      }
                    }}
                  >
                    <label>
                      <input
                        type="file"
                        hidden
                        accept=".pdf"
                        onChange={handleDeclarationFile}
                      />
                      <svg
                        width="39"
                        height="23"
                        viewBox="0 0 64 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M50.0074 47.2839H39.6633H36.8778H36.2763V33.4089H40.8141C41.9649 33.4089 42.6449 32.1012 41.9649 31.1596L33.1377 18.9455C32.5754 18.1609 31.4115 18.1609 30.8492 18.9455L22.0221 31.1596C21.3421 32.1012 22.009 33.4089 23.1729 33.4089H27.7107V47.2839H27.1091H24.3237H12.3318C5.46629 46.9046 0 40.4837 0 33.5266C0 28.7273 2.60237 24.5425 6.46016 22.2802C6.10707 21.3255 5.92399 20.3055 5.92399 19.2332C5.92399 14.3292 9.88639 10.3668 14.7904 10.3668C15.8496 10.3668 16.8696 10.5499 17.8243 10.903C20.662 4.88746 26.7822 0.71582 33.8962 0.71582C43.1026 0.728898 50.6874 7.77753 51.5505 16.7616C58.6253 17.9778 64 24.5295 64 31.9443C64 39.8691 57.8275 46.7346 50.0074 47.2839Z"
                          fill="#FF1B6F"
                        />
                      </svg>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600"
                        }}
                      >
                        Upload Form
                      </Typography>
                    </label>
                  </Box>
                )}

                {declarationFile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "50px 1fr",
                        alignItems: "center",
                        gap: "10px"
                      }}
                    >
                      <svg
                        width="48"
                        height="62"
                        viewBox="0 0 48 62"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M47.6914 16.3477L34.9766 12.7148L31.3438 0H5.91406C2.90452 0 0.464844 2.43968 0.464844 5.44922V56.5508C0.464844 59.5603 2.90452 62 5.91406 62H42.2422C45.2517 62 47.6914 59.5603 47.6914 56.5508V16.3477Z"
                          fill="#4086F4"
                        />
                        <path
                          d="M47.6914 16.3477V56.5508C47.6914 59.5603 45.2517 62 42.2422 62H24.0781V0H31.3438L34.9766 12.7148L47.6914 16.3477Z"
                          fill="#4175DF"
                        />
                        <path
                          d="M47.6914 16.3477H34.9766C32.9785 16.3477 31.3438 14.7129 31.3438 12.7148V0C31.816 0 32.2883 0.181641 32.6151 0.545043L47.1464 15.0763C47.5098 15.4031 47.6914 15.8754 47.6914 16.3477Z"
                          fill="#80AEF8"
                        />
                        <path
                          d="M34.9766 29.1836H13.1797C12.1757 29.1836 11.3633 28.3712 11.3633 27.3672C11.3633 26.3632 12.1757 25.5508 13.1797 25.5508H34.9766C35.9806 25.5508 36.793 26.3632 36.793 27.3672C36.793 28.3712 35.9806 29.1836 34.9766 29.1836Z"
                          fill="#FFF5F5"
                        />
                        <path
                          d="M34.9766 36.4492H13.1797C12.1757 36.4492 11.3633 35.6368 11.3633 34.6328C11.3633 33.6288 12.1757 32.8164 13.1797 32.8164H34.9766C35.9806 32.8164 36.793 33.6288 36.793 34.6328C36.793 35.6368 35.9806 36.4492 34.9766 36.4492Z"
                          fill="#FFF5F5"
                        />
                        <path
                          d="M34.9766 43.7148H13.1797C12.1757 43.7148 11.3633 42.9024 11.3633 41.8984C11.3633 40.8944 12.1757 40.082 13.1797 40.082H34.9766C35.9806 40.082 36.793 40.8944 36.793 41.8984C36.793 42.9024 35.9806 43.7148 34.9766 43.7148Z"
                          fill="#FFF5F5"
                        />
                        <path
                          d="M27.7109 50.9805H13.1797C12.1757 50.9805 11.3633 50.1681 11.3633 49.1641C11.3633 48.1601 12.1757 47.3477 13.1797 47.3477H27.7109C28.7149 47.3477 29.5273 48.1601 29.5273 49.1641C29.5273 50.1681 28.7149 50.9805 27.7109 50.9805Z"
                          fill="#FFF5F5"
                        />
                      </svg>
                      <Typography
                        sx={{
                          fontSize: "13px"
                        }}
                      >
                        {declarationFile?.name}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        gap: "20px"
                      }}
                    >
                      <Typography
                        component={"a"}
                        href={URL.createObjectURL(declarationFile)}
                        sx={{
                          fontSize: "14px",
                          color: "rgba(50, 157, 0, 1)",
                          textDecoration: "none",
                          cursor: "pointer"
                        }}
                        target="_blank"
                      >
                        View
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "red",
                          textDecoration: "none",
                          cursor: "pointer"
                        }}
                        onClick={() => setDeclarationFile()}
                      >
                        Delete
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Button
                  sx={{
                    color: "white",
                    borderRadius: "5px",
                    backgroundColor: "#FF1B6F",
                    textTransform: "capitalize",
                    border: "1px solid #FF1B6F !important",
                    fontSize: "14px",
                    fontWeight: "600",
                    transition: "0.5s ease",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color: "#FF1B6F"
                    }
                  }}
                  onClick={handleMethodApplyClick}
                >
                  Upload
                </Button>
                {/* <UploadButton
                  width="100%"
                  height="50px"
                  // onClickFn={handleVisaSummaryButton}
                  title={"verify"}
                  bgcolor="#FF1B6F"
                /> */}
              </Box>
            </>
          )}

          {Dailogcomponent === "declarationCompleted" && (
            <>
              <Box
                sx={{
                  display: "grid",
                  gap: "20px"
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "'Poppins',sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    ml: 1,
                    mt: 2,
                    textAlign: "center"
                  }}
                >
                  Please upload the declaration document submitted by student.
                </Typography>

                {Boolean(!submitteddeclarationFile) && (
                  <Box
                    sx={{
                      "& label": {
                        display: "flex",
                        gap: "7px",
                        alignItems: "center",
                        border: "1px solid #84848478",
                        padding: "10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        justifyContent: "center"
                      }
                    }}
                  >
                    <label>
                      <input
                        type="file"
                        hidden
                        accept=".pdf"
                        onChange={handleSubmittedDeclarationFile}
                      />
                      <svg
                        width="39"
                        height="23"
                        viewBox="0 0 64 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M50.0074 47.2839H39.6633H36.8778H36.2763V33.4089H40.8141C41.9649 33.4089 42.6449 32.1012 41.9649 31.1596L33.1377 18.9455C32.5754 18.1609 31.4115 18.1609 30.8492 18.9455L22.0221 31.1596C21.3421 32.1012 22.009 33.4089 23.1729 33.4089H27.7107V47.2839H27.1091H24.3237H12.3318C5.46629 46.9046 0 40.4837 0 33.5266C0 28.7273 2.60237 24.5425 6.46016 22.2802C6.10707 21.3255 5.92399 20.3055 5.92399 19.2332C5.92399 14.3292 9.88639 10.3668 14.7904 10.3668C15.8496 10.3668 16.8696 10.5499 17.8243 10.903C20.662 4.88746 26.7822 0.71582 33.8962 0.71582C43.1026 0.728898 50.6874 7.77753 51.5505 16.7616C58.6253 17.9778 64 24.5295 64 31.9443C64 39.8691 57.8275 46.7346 50.0074 47.2839Z"
                          fill="#FF1B6F"
                        />
                      </svg>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600"
                        }}
                      >
                        Upload Document
                      </Typography>
                    </label>
                  </Box>
                )}

                {submitteddeclarationFile && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "50px 1fr",
                        alignItems: "center",
                        gap: "10px"
                      }}
                    >
                      <svg
                        width="48"
                        height="62"
                        viewBox="0 0 48 62"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M47.6914 16.3477L34.9766 12.7148L31.3438 0H5.91406C2.90452 0 0.464844 2.43968 0.464844 5.44922V56.5508C0.464844 59.5603 2.90452 62 5.91406 62H42.2422C45.2517 62 47.6914 59.5603 47.6914 56.5508V16.3477Z"
                          fill="#4086F4"
                        />
                        <path
                          d="M47.6914 16.3477V56.5508C47.6914 59.5603 45.2517 62 42.2422 62H24.0781V0H31.3438L34.9766 12.7148L47.6914 16.3477Z"
                          fill="#4175DF"
                        />
                        <path
                          d="M47.6914 16.3477H34.9766C32.9785 16.3477 31.3438 14.7129 31.3438 12.7148V0C31.816 0 32.2883 0.181641 32.6151 0.545043L47.1464 15.0763C47.5098 15.4031 47.6914 15.8754 47.6914 16.3477Z"
                          fill="#80AEF8"
                        />
                        <path
                          d="M34.9766 29.1836H13.1797C12.1757 29.1836 11.3633 28.3712 11.3633 27.3672C11.3633 26.3632 12.1757 25.5508 13.1797 25.5508H34.9766C35.9806 25.5508 36.793 26.3632 36.793 27.3672C36.793 28.3712 35.9806 29.1836 34.9766 29.1836Z"
                          fill="#FFF5F5"
                        />
                        <path
                          d="M34.9766 36.4492H13.1797C12.1757 36.4492 11.3633 35.6368 11.3633 34.6328C11.3633 33.6288 12.1757 32.8164 13.1797 32.8164H34.9766C35.9806 32.8164 36.793 33.6288 36.793 34.6328C36.793 35.6368 35.9806 36.4492 34.9766 36.4492Z"
                          fill="#FFF5F5"
                        />
                        <path
                          d="M34.9766 43.7148H13.1797C12.1757 43.7148 11.3633 42.9024 11.3633 41.8984C11.3633 40.8944 12.1757 40.082 13.1797 40.082H34.9766C35.9806 40.082 36.793 40.8944 36.793 41.8984C36.793 42.9024 35.9806 43.7148 34.9766 43.7148Z"
                          fill="#FFF5F5"
                        />
                        <path
                          d="M27.7109 50.9805H13.1797C12.1757 50.9805 11.3633 50.1681 11.3633 49.1641C11.3633 48.1601 12.1757 47.3477 13.1797 47.3477H27.7109C28.7149 47.3477 29.5273 48.1601 29.5273 49.1641C29.5273 50.1681 28.7149 50.9805 27.7109 50.9805Z"
                          fill="#FFF5F5"
                        />
                      </svg>
                      <Typography
                        sx={{
                          fontSize: "13px"
                        }}
                      >
                        {submitteddeclarationFile?.name}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        gap: "20px"
                      }}
                    >
                      <Typography
                        component={"a"}
                        href={URL.createObjectURL(submitteddeclarationFile)}
                        sx={{
                          fontSize: "14px",
                          color: "rgba(50, 157, 0, 1)",
                          textDecoration: "none",
                          cursor: "pointer"
                        }}
                        target="_blank"
                      >
                        View
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "red",
                          textDecoration: "none",
                          cursor: "pointer"
                        }}
                        onClick={() => setsubmittedDeclarationFile()}
                      >
                        Delete
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Button
                  sx={{
                    color: "white",
                    borderRadius: "5px",
                    backgroundColor: "#FF1B6F",
                    textTransform: "capitalize",
                    border: "1px solid #FF1B6F !important",
                    fontSize: "14px",
                    fontWeight: "600",
                    transition: "0.5s ease",
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color: "#FF1B6F"
                    }
                  }}
                  onClick={handleMethodApplyClick}
                >
                  Upload
                </Button>
                {/* <UploadButton
                  width="100%"
                  height="50px"
                  // onClickFn={handleVisaSummaryButton}
                  title={"verify"}
                  bgcolor="#FF1B6F"
                /> */}
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
