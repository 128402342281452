import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";

import { BiLeftArrowAlt } from "react-icons/bi";

import { MainContainer } from "../../content/content.element";
import { allRoleFindAction } from "../../../actions/roleMangmentAction";
import {
  editedEmployeeDataAction,
  employeedetailsShowAction,
  findAllEmployeeAction
} from "../../../actions/employeeManagmentAction";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { TypographyText1 } from "../../customComponent/Typography";
import InputField, {
  SelectInputField,
  SelectInputFieldForBranch
} from "../../customComponent/InputField";
import { SubmitButton } from "../../customComponent/Buttons";
import { findBranchMasterAction } from "../../../actions/branchMasterActions";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { countries } from "countries-list";

const EditEmployeeDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const fileInputRef = useRef();

  const { allRoleFindLoading, allRoleFindSuccess, allRoleFindErr } =
    useSelector((state) => {
      return state.allRoleFind;
    });

  //  selectors
  let {
    showDetailsEmployeeLoading,
    showDetailsEmployeeSuccess,
    showDetailsEmployeeErr
  } = useSelector((state) => {
    return state.employeedetailsShow;
  });

  let {
    branchMasterFindLoading,
    branchMasterFindSuccess,
    branchMasterFindErr
  } = useSelector((state) => {
    return state.findBranchMaster;
  });

  let { findEmployeeSuccess } = useSelector((state) => {
    return state.findAllEmployee;
  });

  let { editedEmployeeDataSuccess } = useSelector((state) => {
    return state.editedEmployeeData;
  });
  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  const [editEmployeeData, setEditEmployeeData] = useState({});
  const [branchList, setBranchList] = useState();
  const [avatarFile, setAvatarFile] = useState(editEmployeeData?.img_url);
  const [name, setName] = useState(editEmployeeData?.firstName);
  const [phone, setPhone] = useState(editEmployeeData?.mobile);
  const [email, setEmail] = useState(editEmployeeData?.email);
  const [role, setRole] = useState(
    showDetailsEmployeeSuccess?.users?.role?.roleName
  );
  // showDetailsEmployeeSuccess?.showDetails.fk_role_id
  const [roleId, setRoleId] = useState(showDetailsEmployeeSuccess?.users?.role);
  const [isLead_B_to_C, setIsLeadBtoC] = useState(false);
  const [isApplicationHandler, setIsApplicationHandler] = useState(false);
  const [isUniversityHandler, setIsUniversityHandler] = useState(false);

  const [adharNumber, setAdharNumber] = useState(
    editEmployeeData?.showDetails?.adharNumber
  );

  const [panNumber, setPanNumber] = useState(
    editEmployeeData?.showDetails?.panNumber
  );

  const [location, setLocation] = useState(
    editEmployeeData?.showDetails?.country_code
  );

  const [branch, setBranch] = useState([]);

  const [teamLeadId, setTeamLeadId] = useState("");
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [isTeleCaller, setIsTeleCaller] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [allEmplyeeData, setAllEmployeeData] = useState([]);
  const [allBtoCEmployeeData, setAllBtoCEmployeeData] = useState([]);
  const [error, setError] = useState();
  const [teamLeadBtoCid, setTeamLeadBtoCid] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [designation, setDesignation] = useState("");


  const allCountries = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name,
    phone: `+${country.phone}`
  }));

  //hook effect

  useEffect(() => {
    if (showDetailsEmployeeSuccess?.users) {
      console.log(showDetailsEmployeeSuccess, "showDetailsEmployeeSuccess");
      setEditEmployeeData(showDetailsEmployeeSuccess?.users);
      setAvatarFile(showDetailsEmployeeSuccess?.users?.img_url);
      setName(showDetailsEmployeeSuccess?.users?.firstName);
      setPhone(showDetailsEmployeeSuccess?.users?.mobile);
      setEmail(showDetailsEmployeeSuccess?.users?.email);
      setRole(showDetailsEmployeeSuccess?.users?.role?.roleName);
      setRoleId(showDetailsEmployeeSuccess?.users?.role);
      setAdharNumber(showDetailsEmployeeSuccess?.users?.adharNumber);
      setPanNumber(showDetailsEmployeeSuccess?.users?.panNumber);
      setLocation(showDetailsEmployeeSuccess?.users?.country_code);
      setIsTeamLead(showDetailsEmployeeSuccess?.users?.is_lead);
      setIsTeleCaller(showDetailsEmployeeSuccess?.users?.isTeleCaller);
      setBranch(
        showDetailsEmployeeSuccess?.users?.user_Branches.map(
          (value) => value.branch_master
        )
      );
      setTeamLeadId(showDetailsEmployeeSuccess?.users?.team_lead_id);
      setIsManager(showDetailsEmployeeSuccess?.users?.isManager);
      setIsLeadBtoC(showDetailsEmployeeSuccess?.users?.is_Lead_B_to_C);
      setIsApplicationHandler(
        showDetailsEmployeeSuccess?.users?.is_applicationHandler
      );
      setIsUniversityHandler(
        showDetailsEmployeeSuccess?.users?.is_universityHandler
      );
      setTeamLeadBtoCid(showDetailsEmployeeSuccess?.users?.Lead_B_to_C_ID);
      setCountryCode(
        showDetailsEmployeeSuccess?.users?.mobile_countryCode
          ? showDetailsEmployeeSuccess?.users?.mobile_countryCode
          : "+91"
      );
      setDesignation(showDetailsEmployeeSuccess?.users?.designation)
    }
  }, [showDetailsEmployeeSuccess]);
  useEffect(() => {
    if (branchMasterFindSuccess) {
      setBranchList(branchMasterFindSuccess);
    }
  }, [branchMasterFindSuccess]);

  useEffect(() => {
    if (editedEmployeeDataSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `Details edited successfully`, "success");
      dispatch(successTrueMsgAction(false));
      navigate(`/Administration/employee/details/${id}`);
    }
  }, [editedEmployeeDataSuccess]);

  useEffect(() => {
    if (findEmployeeSuccess) {
      setAllEmployeeData(findEmployeeSuccess.filter((user) => user.is_lead));
      setAllBtoCEmployeeData(
        findEmployeeSuccess.filter((user) => user.is_Lead_B_to_C)
      );
    }
  }, [findEmployeeSuccess]);

  useEffect(() => {
    dispatch(allRoleFindAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findAllEmployeeAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(employeedetailsShowAction(id));
  }, [dispatch]);

  const handleIconButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);

    // Set the previewImage state when the reader has finished loading the image
    reader.onloadend = () => {
      setAvatarFile(reader.result);
    };
  };

  const handleRole = (e) => {
    const roleName = e.target.value;
    setRole(roleName);
    const findRoleId = allRoleFindSuccess.find(
      (value) => value.roleName == roleName
    );
    setRoleId(findRoleId);
  };

  const validate = () => {
    let errors = {};

    if (name === "") {
      errors.name = "Name is required";
    }
    if (!location === "" || !location) {
      errors.location = "Location is required";
    }
    if (phone !== "") {
      if (!/^\d{10}$/.test(phone)) {
        errors.phone = "Phone number must be 10 digits";
      }
    }
    if (email === "") {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Invalid email address";
    }
    if (role === "") {
      errors.role = "Role is required";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Perform form validation
    if (validate()) {
      dispatch(
        editedEmployeeDataAction(
          id,
          avatarFile,
          name,
          phone,
          email,
          roleId,
          adharNumber,
          panNumber,
          location,
          branch,
          teamLeadId,
          isTeamLead,
          isTeleCaller,
          isManager,
          isLead_B_to_C,
          isUniversityHandler,
          isApplicationHandler,
          teamLeadBtoCid,
          countryCode,
          designation
        )
      );
      dispatch(successTrueMsgAction(true));
    }

    // Perform form submission or data handling logic here
    // Reset form fields after submission if needed
    // setAvatarFile(null);
    // setName("");
    // setPhone("");
    // setEmail("");
    // setRole("");
    // setAdharNumber("");
    // setPanNumber("");
    // setPassword("");
    // setCPassword("");
  };

  const handleObjectInArray = (array, obj) => {
    const index = array.findIndex((item) => item.id === obj.id);
    console.log(array, "array");
    console.log(obj, "obj");
    console.log(index, "index");

    if (index !== -1) {
      console.log("spliced");
      array.splice(index, 1);
    } else {
      console.log("pushed");
      array.push(obj);
    }

    return array;
  };

  const handleBranchChange = (event) => {
    // const { value } = event.target;
    // const filteredArray = value;
    // .filter((obj) =>
    //   obj.hasOwnProperty("employeeBranchName")
    // );

    setBranch(event);
    // setBranch((prevRanking) => {
    //   const updatedRanking = [...prevRanking];

    //   filteredArray.forEach((selectedItem) => {
    //     const existingIndex = updatedRanking.findIndex(
    //       (item) => item.id === selectedItem.id
    //     );

    //     if (existingIndex !== -1) {

    //       updatedRanking.splice(existingIndex, 1);
    //     } else {
    //       updatedRanking.push({
    //         name: selectedItem.employeeBranchName,
    //         id: selectedItem.id,
    //       });
    //     }
    //   });

    //   return updatedRanking;
    // });

    setError(false);
  };

  // country Code
  const handleCountryCodeChange = (event) => {
    let value = event.target.value;
    if (value) {
      setCountryCode(value);
    }
  };

  console.log(role, "roleeeeeeeeeeeeeeee");
  console.log(roleId, "roleIddddddddddd");

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader
        headTitle="Edit Employee"
        url={`/Administration/employee/details/${id}`}
      />
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "16px",
          marginTop: "30px"
        }}
      >
        <TypographyText1 variant="h6" title="Edit Profile Details" />
      </Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontFamily: "'Open Sans', sans-serif",
          color: "rgba(172, 177, 198, 1)",
          ml: 1.5
        }}
      >
        Profile Picture
      </Typography>

      <Box mt={3} mb={5}>
        <input
          type="file"
          accept="image/*"
          id="avatar-file"
          style={{ display: "none" }}
          onChange={handleAvatarChange}
          ref={fileInputRef}
        />

        <Avatar
          src={avatarFile}
          alt="Avatar"
          sx={{ width: 120, height: 120, backgroundColor: "#F4F4F4", m: 1.5 }}
          onClick={handleIconButtonClick}
        >
          <label htmlFor="avatar-file">
            {!avatarFile ? (
              <img
                src="/menuIcons/fileUploadCenter.png"
                alt="gsl"
                style={{
                  width: 35,
                  height: 35,
                  cursor: "pointer"
                }}
              />
            ) : (
              <img
                src={avatarFile}
                alt="gsl"
                style={{
                  width: 35,
                  height: 35,
                  cursor: "pointer"
                }}
              />
            )}
          </label>
        </Avatar>
      </Box>
      <form onSubmit={handleFormSubmit} style={{ marginBottom: "18px" }}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            {/* <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            /> */}

            <InputField
              label="Name"
              handleChange={(e) => setName(e.target.value)}
              widthSize="99%"
              value={name}
              required
              paramses
              InputType="text"
              dispcriptionHeight
              error={Boolean(error?.name)}
              helperText={error?.name}
            />
          </Grid>

          <Grid item xs={0.8}>
            <SelectInputField
              handleSelectChange={handleCountryCodeChange}
              value={countryCode}
              mapValue={allCountries}
              mapMethod="countryCode"
            />
          </Grid>
          <Grid item lg={5.1} xs={12}>
            {/* <TextField
              label="Phone"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            /> */}
            <InputField
              label="Phone"
              handleChange={(e) => {
                setPhone(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["phone"]: ""
                }));
              }}
              widthSize="99%"
              value={phone}
              // required
              paramses
              InputType="text"
              dispcriptionHeight
              error={Boolean(error?.phone)}
              helperText={error?.phone}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Email"
              handleChange={(e) => setEmail(e.target.value)}
              widthSize="99%"
              value={email}
              required
              paramses
              InputType="text"
              dispcriptionHeight
              error={Boolean(error?.email)}
              helperText={error?.email}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            {/* <FormControl label={role} fullWidth>
              <InputLabel>{tempRole}</InputLabel>
              <Select
                value={role}
                onChange={(e) => {
                  console.log(
                    tempRole,
                    "UNIVERSITY_SUBMIT_ERRUNIVERSITY_SUBMIT_ERR"
                  );
                  setRole(e.target.value);
                }}
              >
                {allRoleFindSuccess?.map((data) => (
                  <MenuItem value={data.id}>{data.roleName}</MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <SelectInputField
              label="choose role"
              handleSelectChange={(e) => {
                handleRole(e);
              }}
              widthSize="75px"
              value={role}
              mapValue={allRoleFindSuccess}
              mapMethod="tempRole"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputField
              label="Designation"
              handleChange={(e) => {
                setDesignation(e.target.value);
              }}
              value={designation}
              widthSize="98.4%"
              // error={Boolean(error?.adharNumber)}
              // helperText={error?.adharNumber}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Adhar Number"
              handleChange={(e) => setAdharNumber(e.target.value)}
              widthSize="99%"
              value={adharNumber}
              // required
              paramses
              InputType="text"
              dispcriptionHeight
              // error={Boolean(error?.adharNumber)}
              // helperText={error?.adharNumber}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Pan Number"
              handleChange={(e) => setPanNumber(e.target.value)}
              widthSize="99%"
              value={panNumber}
              // required
              paramses
              InputType="text"
              dispcriptionHeight
              // error={Boolean(error?.panNumber)}
              // helperText={error?.panNumber}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputField
              label="Location"
              handleChange={(e) => {
                setLocation(e.target.value);
              }}
              widthSize="98.4%"
              value={location}
              required
              paramses
              InputType="text"
              dispcriptionHeight
              error={Boolean(error?.location)}
              helperText={error?.location}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <SelectInputFieldForBranch
              label="Branch"
              handleSelectChange={handleBranchChange}
              widthSize="49px"
              value={branch}
              mapValue={branchList}
              mapMethod="branchMaster"
              multiple="true"
            />
          </Grid>
          {!isTeamLead && (
            <Grid item xs={12}>
              <SelectInputField
                label="Choose B2B lead"
                handleSelectChange={(e) => setTeamLeadId(e.target.value)}
                widthSize="49px"
                value={teamLeadId}
                mapValue={allEmplyeeData}
                mapMethod="teams"
              />
            </Grid>
          )}

          {!isLead_B_to_C && (
            <Grid item xs={12}>
              <SelectInputField
                label="Choose B2C lead"
                handleSelectChange={(e) => setTeamLeadBtoCid(e.target.value)}
                widthSize="49px"
                value={teamLeadBtoCid}
                mapValue={allBtoCEmployeeData}
                mapMethod="teams"
              />
            </Grid>
          )}

          <Grid item xs={6} mt={-1}>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 146, 166, 1)"
                  }}
                >
                  Team Lead B2B
                </Typography>
                <Checkbox
                  checked={isTeamLead}
                  onChange={(e) => {
                    setIsTeamLead(e.target.checked);
                    setTeamLeadId("");
                  }}
                  style={{ color: "#f81b82" }}
                />
              </Box>
              {/* <SwitchButton label="Team Lead" /> */}
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 146, 166, 1)"
                  }}
                >
                  TeleCaller
                </Typography>
                <Checkbox
                  checked={isTeleCaller}
                  onChange={(e) => {
                    setIsTeleCaller(e.target.checked);
                    // setTeamLeadId("");
                  }}
                  style={{ color: "#f81b82" }}
                />
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 146, 166, 1)"
                  }}
                >
                  Manager
                </Typography>
                <Checkbox
                  checked={isManager}
                  onChange={(e) => {
                    setIsManager(e.target.checked);
                    // setTeamLeadId("");
                  }}
                  style={{ color: "#f81b82" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 146, 166, 1)"
                  }}
                >
                  Team Lead B2C
                </Typography>
                <Checkbox
                  checked={isLead_B_to_C}
                  onChange={(e) => {
                    setIsLeadBtoC(e.target.checked);
                    // setTeamLeadId("");
                  }}
                  style={{ color: "#f81b82" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 146, 166, 1)"
                  }}
                >
                  Application handler
                </Typography>
                <Checkbox
                  checked={isApplicationHandler}
                  onChange={(e) => {
                    setIsApplicationHandler(e.target.checked);
                    // setTeamLeadId("");
                  }}
                  style={{ color: "#f81b82" }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 146, 166, 1)"
                  }}
                >
                  University handler
                </Typography>
                <Checkbox
                  checked={isUniversityHandler}
                  onChange={(e) => {
                    setIsUniversityHandler(e.target.checked);
                    // setTeamLeadId("");
                  }}
                  style={{ color: "#f81b82" }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "right" }}>
          <SubmitButton
            title="Save"
            submit="submit"
            widthSize="100px"
            heightSize="40px"
            type="submit"
            handleSubmit
          />
        </Grid>
      </form>
    </MainContainer>
  );
};

export default EditEmployeeDetails;
